import { useCallback, useMemo } from 'react';
import { flatten } from 'lodash';
import { BladeSide } from './BladeDamagePlotContainer';

/**
 * Component for drawing a blade side shape (i.e. pressure side, suction side) given a x/y d3 scales
 * and a set of coordinates.
 */

type SideShapeProps = {
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  points: [number, number][];
  bladeSide: BladeSide;
};

export const SideShape: React.FunctionComponent<SideShapeProps> = ({
  xScale,
  yScale,
  points,
  bladeSide,
}) => {
  const xRange = useMemo(() => xScale.range(), [xScale]);
  const yRange = useMemo(() => yScale.range(), [yScale]);

  // Translate the height of the contour line to the correct height
  const contourLineMultiplier = useCallback(
    (point: number, index: number) => {
      if (bladeSide === BladeSide.PressureSide) {
        return point + 0.1 * (index + 1) * (yRange[0] - point);
      }
      return 0.1 * (index + 1) * point;
    },
    [bladeSide]
  );

  return (
    <>
      <path
        d={[
          'M',
          xRange[0],
          yRange[0],
          ...flatten(points.map(point => ['L', xScale(point[0]), yScale(point[1])])),
          'L',
          xScale(points[points.length - 1][0]),
          yRange[0],
          'L',
          xRange[0],
          yRange[0],
        ].join(' ')}
        fill="#F7F7F7"
        stroke="#0000001F"
      />
      {
        /* Creates a contour line at each 10% chord */
        [...Array(9)].map((_, index) => (
          <path
            key={index}
            d={[
              ...flatten(
                points.map((point, i) => [
                  i === 0 ? 'M' : 'L',
                  xScale(point[0]),
                  contourLineMultiplier(yScale(point[1]), index),
                ])
              ),
            ].join(' ')}
            fill="none"
            stroke="#0000000D"
          />
        ))
      }
    </>
  );
};
