import styled from 'styled-components';
import { Form, Checkbox } from 'antd';
import { FlexContainer, SpacedContainer } from 'utils/layouts/containers';

export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const FormActions = styled.div`
  display: flex;
  button:first-child {
    margin: 0 0.5rem 0 auto;
  }
`;

export const RowContainer = styled(FlexContainer)`
  margin-bottom: 1em;
`;

export const FormItemContainer = styled(SpacedContainer)`
  flex: 1;
`;

export const StyledCheckbox = styled(Checkbox)`
  align-self: flex-start;
  margin-top: 5px;
`;
