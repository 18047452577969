// JD: This is meant to be an updated form of the TaskAssetChooser. It will:
// * Fetch TaskAsset data to act as a default for the asset on a task.
// * Auto-populate the AssetPicker component with the associated asset for that Task's associated asset.
import { useEffect, useMemo } from 'react';

import { AssetPicker } from 'horizon/components/Assets/AssetPicker';
import { AssetHierarchyItem } from 'horizon/components/Assets/AssetPicker/types';
import { Spinner } from 'components/ui';
import { convertToAssetHierarchyItem, getTaskAssetInfo } from '../helpers';
import { TAssetPickerResponse } from '../types';
import { useGetTaskAssetQuery, useGetAssetDataForTaskAssetChooserQuery } from 'types/atlas-graphql';

interface ITaskAssetPickerProps {
  taskId: string | undefined;
  showLoadingIndicator?: boolean;
  onChange?: (value?: TAssetPickerResponse | AssetHierarchyItem) => void;
  disableAll?: boolean;
  selectedAssetId?: string | undefined;
  initialAssetId?: string;
  setInitialAssetId?: (assetId: string | undefined) => void;
}

export const TaskAssetPicker: React.FunctionComponent<ITaskAssetPickerProps> = ({
  taskId,
  showLoadingIndicator = false,
  onChange = () => {},
  disableAll = false,
  selectedAssetId = undefined,
  initialAssetId,
  setInitialAssetId,
}) => {
  const getValueFromAssetPicker = (assetInfo: AssetHierarchyItem | undefined) => {
    if (assetInfo) {
      onChange({
        assetId: assetInfo.assetId,
        assetTypeId: assetInfo?.assetTypeId,
        assetTypeName: assetInfo?.assetTypeName,
        isLocation: ['site', 'location'].includes(assetInfo?.assetTypeName?.toLowerCase() ?? ''),
        isBlade: ['blade'].includes(assetInfo?.assetTypeName?.toLowerCase() ?? ''),
      });
    }
  };

  // Get TaskAsset for provided task
  const { loading: taskAssetLoading, data } = useGetTaskAssetQuery({
    variables: { taskId },
    skip: !taskId || selectedAssetId !== undefined,
  });

  // Get Asset Data for this component when we select an asset or have initial asset id data.
  // Prioritize querying data based on selected assets over initialAssetId, selected should only get
  // populated when a user changes a dropdown.
  const { loading: assetLoading, data: assetData } = useGetAssetDataForTaskAssetChooserQuery({
    variables: { id: selectedAssetId ?? initialAssetId },
    skip: !selectedAssetId && !initialAssetId,
  });

  const taskAsset = getTaskAssetInfo(data?.task as any);

  // This useMemo is ugly, but it helps satisfy the swiss army knife that is the DetailedTaskForm component.
  // This component supports Task Creation on Alerts (No Task ID, but has Asset Data), and from Work Orders.
  const assetHierarchyItem = useMemo(() => {
    const selectedAsset = assetData?.assetWithMetadata;
    const convertedAsset = convertToAssetHierarchyItem(selectedAsset ?? taskAsset);
    return convertedAsset;
  }, [taskAsset, assetData]);

  useEffect(() => {
    if (!initialAssetId && setInitialAssetId) {
      setInitialAssetId(assetHierarchyItem?.assetId);
    }
  }, [assetHierarchyItem, initialAssetId, setInitialAssetId]);

  if (assetLoading || taskAssetLoading) {
    return showLoadingIndicator ? <Spinner /> : null;
  }

  return (
    <AssetPicker
      value={assetHierarchyItem}
      onChange={getValueFromAssetPicker}
      initialAsset={taskAsset ?? null}
      assetTypeId={taskAsset?.assetType?.id ?? null}
      disableAll={disableAll}
      shouldIncludeAssetTypeSelector={true}
    />
  );
};
