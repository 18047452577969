import { Dispatch, useState } from 'react';
import { Button, Modal } from 'components/ui';
import { Select, Switch } from 'antd5';
import { ColorCodingAttribute, PlotStyleState } from '../BladeDamagePlotContainer';
import { StyleOptionContainer } from './PlotStyleOptionsModal.style';

type PlotStyleOptionsModalProps = {
  plotStyleState: PlotStyleState;
  dispatchPlotStyleState: Dispatch<Partial<PlotStyleState>>;
};

export const PlotStyleOptionsModal: React.FunctionComponent<PlotStyleOptionsModalProps> = ({
  plotStyleState,
  dispatchPlotStyleState,
}) => {
  const [workingState, setWorkingState] = useState<PlotStyleState>(plotStyleState);

  return (
    <Modal
      open={plotStyleState.modalOpen}
      onCancel={() => dispatchPlotStyleState({ modalOpen: false })}
      title="Plot Style Options"
      footer={
        <div>
          <Button onClick={() => dispatchPlotStyleState({ modalOpen: false })}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => dispatchPlotStyleState({ ...workingState, modalOpen: false })}
          >
            Apply
          </Button>
        </div>
      }
    >
      <StyleOptionContainer>
        <Switch
          onChange={checked => setWorkingState({ ...workingState, colorCodingEnabled: checked })}
          checked={workingState.colorCodingEnabled}
        />
        <div>
          <p>Color Coding</p>
          <Select
            options={Object.keys(ColorCodingAttribute).map(attribute => ({ value: attribute }))}
            value={workingState.colorCodingAttribute}
            showSearch={true}
            onChange={attribute =>
              setWorkingState({ ...workingState, colorCodingAttribute: attribute })
            }
          />
        </div>
      </StyleOptionContainer>
    </Modal>
  );
};
