import { useState, useMemo, useEffect, useCallback } from 'react';
import { get, isEqual, isEqualWith, isEmpty } from 'lodash';
import moment from 'moment';
import { Form } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'components/ui';

import { DATE_FORMAT } from 'utils/constants';
import { usePermissions } from 'utils/usePermissions';
import { editable } from '../../editable';
import {
  StyledForm,
  FormActions,
  RowContainer,
  FormItemContainer,
  StyledCheckbox,
} from './BulkEdit.style';
import { getEnableTooltipTitle } from './helpers';

import { renderColumn } from 'components/data/helpers';

function getKey(column) {
  return column.key || column.dataIndex;
}

// produce consolidated values object. Values that are all the same are retained,
// values that are different between items show up as blank, with 'multiple values' placeholder.
export function getInitialFormValues(selected, fields) {
  const [firstRow, ...otherRows] = selected;
  return fields.reduce(
    (initialValues, { key }) => {
      const multiple = otherRows.some(row => !isEqual(row[key], firstRow[key]));
      if (multiple) {
        initialValues.multipleVals.add(key);
      }
      initialValues[key] = multiple ? undefined : firstRow[key];

      return initialValues;
    },
    {
      multipleVals: new Set(),
    }
  );
}

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 }, lg: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

export default function BulkEditForm({
  columns,
  records,
  requiredFields = [],
  onSubmit,
  onClose,
  skipEditCheck = false,
  getInitialValues = getInitialFormValues,
  // The Bulk Edit form contains a generic tooltip.
  // This allows you to append additional content if needed.
  additionalTooltipContent = '',
  // This content will appear before the form's actions
  additionalFooterContent,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [columnsEnabled, setColumnsEnabled] = useState({});
  const { CanAccess } = usePermissions();

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const initialValues = useMemo(() => getInitialValues(records, columns), [records, columns]);

  const saveDisabled = useMemo(
    () => !Object.values(columnsEnabled).filter(Boolean).length,
    [columnsEnabled]
  );

  const dependantFields = useMemo(() => {
    const map = new Map();

    columns.forEach(col => {
      const { key, dependsOn } = col;
      dependsOn?.forEach(dep => {
        const existing = map.get(dep);
        if (existing) {
          existing.push(key);
        } else {
          map.set(dep, [key]);
        }
      });
    });

    return map;
  }, [columns]);

  const handleValuesChange = useCallback(
    (changedValues, allValues) => {
      const checksToEnable = {};

      Object.keys(changedValues).forEach(key => {
        const dependants = dependantFields.get(key);
        if (dependants) {
          dependants.forEach(dep => {
            form.setFieldsValue({ [dep]: '' });
            checksToEnable[dep] = true;
          });
        }
      });

      setColumnsEnabled(prev => ({
        ...prev,
        ...checksToEnable,
      }));
    },
    [dependantFields, form]
  );

  function getUserEdits(values) {
    return Object.keys(values).reduce((obj, key) => {
      const initialVal = initialValues[key];
      const currentVal = values[key];

      // if a field was undefined and now is an empty string, exclude
      const bothEmpty =
        isEmpty(initialVal) &&
        ((typeof currentVal === 'string' && isEmpty(currentVal)) ||
          (typeof currentVal === 'number' && !isFinite(currentVal)));

      // check if user value is different from the existing value
      if (
        !bothEmpty &&
        !isEqualWith(initialVal, currentVal, (initialVal, currentVal) => {
          if (moment.isMoment(currentVal)) {
            return initialVal === currentVal.format(DATE_FORMAT);
          }
          return isEqual(initialVal, currentVal);
        })
      ) {
        obj[key] = currentVal;
      }

      // add user value if there are multiple values for a key
      if (initialValues.multipleVals.has(key)) {
        obj[key] = currentVal;
      }

      return obj;
    }, {});
  }

  async function handleFinish(values) {
    const { collaborators, ...restOfValues } = values;
    let userEdits = getUserEdits(restOfValues);

    // The collaborator component used for bulk collaborator changes returns an object
    // that needs to be spread
    if (collaborators) {
      userEdits = { ...userEdits, ...collaborators };
    }

    if (isEmpty(userEdits) && !skipEditCheck) {
      return;
    }

    // filter out any edits that are from fields without a checkbox
    const filteredEdits = Object.keys(userEdits).reduce((obj, fieldName) => {
      // collaboratorUpdateAction isn't an actual field but comes from the spread collaborator value
      if (
        columnsEnabled[fieldName] ||
        (fieldName === 'collaboratorUpdateAction' && columnsEnabled['collaborators'] === true)
      ) {
        obj[fieldName] = userEdits[fieldName];
      }
      return obj;
    }, {});

    setIsLoading(true);
    await onSubmit(filteredEdits);

    onClose();
  }

  function handleEnabledCheck(e) {
    const { checked, name } = e.target;

    const dependants = dependantFields.get(name);
    const fieldsToUpdate = {};
    if (dependants && !checked) {
      dependants.forEach(dep => {
        fieldsToUpdate[dep] = false;
      });
    } else if (dependants) {
      if (form.getFieldValue(name) !== initialValues[name]) {
        dependants.forEach(dep => {
          fieldsToUpdate[dep] = true;
        });
      }
    }

    setColumnsEnabled({
      ...columnsEnabled,
      ...fieldsToUpdate,
      [name]: checked,
    });
  }

  // some user roles can only edit certain fields, so filter out uneditable fields
  const editableColumns = columns.filter(col => {
    const { editRule } = col;
    return editRule && CanAccess({ rules: [editRule] });
  });

  return (
    <StyledForm
      id="bulk-edit-modal"
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      form={form}
    >
      <Tooltip title={getEnableTooltipTitle(additionalTooltipContent)}>
        <label>
          Enable &nbsp;
          <QuestionCircleFilled />
        </label>
      </Tooltip>

      {editableColumns.map(column => {
        // attempt to get initial value based on column data
        let initialValue = column.getInitialValue
          ? column.getInitialValue(initialValues)
          : get(initialValues, column.dataIndex);

        const key = getKey(column);
        const disabled = !columnsEnabled[key];
        const required = requiredFields.includes(column.key);

        const disableCheck =
          column.disableCheckbox ||
          column.dependsOn?.some(dep => {
            const depVal = form.getFieldValue(dep);
            return (
              (depVal && depVal !== initialValues[dep]) ||
              (!depVal && initialValues.multipleVals.has(dep))
            );
          });

        return (
          <RowContainer key={key}>
            {/* FIXME: Using this styled checkbox is a hack to prevent alignment
            issues between the checkbox and the form field when the field also
            conditionally displays a warning alert.
            Currently this is the case for the Archived field specifically */}
            <StyledCheckbox
              disabled={disableCheck}
              checked={columnsEnabled[key]}
              onChange={handleEnabledCheck}
              name={key}
            />
            <FormItemContainer>
              <Form.Item
                {...formItemLayout}
                key={key}
                label={column.title}
                name={key}
                initialValue={initialValue}
                form={form}
                rules={[
                  {
                    required,
                    validator({ required }, value) {
                      if (required && !initialValues.multipleVals.has(key) && !value) {
                        return Promise.reject('Please enter a value.');
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {renderColumn(editable(column), {
                  editable: true,
                  disabled,
                  placeholder: initialValues.multipleVals.has(key) ? 'multiple values' : null,
                  form,
                })}
              </Form.Item>
            </FormItemContainer>
          </RowContainer>
        );
      })}
      {additionalFooterContent && additionalFooterContent}
      <FormActions>
        <Button _version={4} onClick={onClose}>
          Cancel
        </Button>
        <Button
          _version={4}
          type="primary"
          htmlType="submit"
          loading={isLoading}
          data-testid="save-btn"
          id="bulk-edit-submit"
          disabled={saveDisabled}
        >
          {`Edit ${records.length} items`}
        </Button>
      </FormActions>
    </StyledForm>
  );
}
