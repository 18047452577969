import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Modal } from 'antd';
import { BulkEdit } from 'utils/editable';
import { getPrimaryObservationGroupForDamage } from 'horizon/components/Damages/utils';

import {
  AtlasGqlHorizonDamage,
  useUpdateHorizonDamageObservationGroupMutation,
  useSetHorizonDamageConfirmationStatusMutation,
} from 'types/atlas-graphql';
import { TEditColumnData } from 'components/data/types';

interface DamagesBulkEditProps {
  selected: AtlasGqlHorizonDamage[];
  columns: TEditColumnData[];
  onFinish: () => void;
}

export const DamagesBulkEdit: React.FunctionComponent<DamagesBulkEditProps> = ({
  selected,
  columns,
  onFinish,
}) => {
  const [values, setValues] = useState<{ [key: string]: any }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');

  const [updateGroupAttributes] = useUpdateHorizonDamageObservationGroupMutation({
    update: (cache, { data }) => {
      const updatedGroup = data?.updateHorizonDamageObservationGroup;
      if (
        updatedGroup?.isPrimaryObservationGroup === true &&
        updatedGroup?.damageId &&
        updatedGroup?.groupAttrs
      ) {
        cache.modify({
          id: `HorizonDamage:${updatedGroup.damageId}`,
          fields: {
            primaryObservationGroupAttrs(currentAttrs) {
              return updatedGroup.groupAttrs;
            },
          },
        });
      }
    },
  });

  const [setConfirmationStatus] = useSetHorizonDamageConfirmationStatusMutation();

  const handleSubmit = async (values: { [key: string]: any }) => {
    const groupsWithOneObservation = selected.filter(damage => {
      const primaryObservationGroup = getPrimaryObservationGroupForDamage(damage);
      return primaryObservationGroup?.observations?.length === 1;
    });

    if (groupsWithOneObservation.length === selected.length) {
      // all groups have 1 observation
      await handleBulkEdit(values);
    } else {
      if (groupsWithOneObservation.length === 0) {
        // all groups have > 1 observation
        setModalText(
          'This action will apply to Damages with multiple Observations ' +
            'in their Primary Observation Group. For those Damages, the action ' +
            'will set attributes at the Observation Group level without changing ' +
            'the Observation attributes.'
        );
      } else {
        // groups have a mix of observations numbers
        setModalText(
          'This action will apply to multiple Damages, some of which have ' +
            'multiple Observations in their Primary Observation Groups. For ' +
            'those Damages, this action will set attributes at the Observation Group ' +
            'level without changing the attributes of the component Observations.'
        );
      }

      setValues(values);
      setModalOpen(true);
    }
  };

  const handleBulkEdit = async (values: { [key: string]: any }) => {
    const { confirmationStatus, ...editedAttrs } = values;

    setIsLoading(true);
    await Promise.all([
      // bulk edit mutations for confirmation status
      ...(confirmationStatus
        ? selected.map(damage => {
            return setConfirmationStatus({
              variables: {
                input: {
                  id: damage.id,
                  confirmationStatus,
                },
              },
            });
          })
        : []),
      // bulk edit mutations for attributes
      ...(!isEmpty(editedAttrs)
        ? selected.map(damage => {
            const mergedAttrs = { ...damage.primaryObservationGroupAttrs, ...editedAttrs };
            const attrs = Object.fromEntries(
              Object.keys(mergedAttrs)
                .map(key => {
                  const value = mergedAttrs[key];

                  if (typeof value === 'object' && 'toDate' in value) {
                    return [key, value.format('YYYY-MM-DD')];
                  }

                  return [key, value];
                })
                .filter(
                  ([key, value]) =>
                    (typeof value === 'string' && !isEmpty(value)) ||
                    (typeof value === 'number' && isFinite(value))
                )
            );

            const primaryObservationGroup = getPrimaryObservationGroupForDamage(damage);
            if (primaryObservationGroup) {
              return updateGroupAttributes({
                variables: {
                  input: {
                    id: primaryObservationGroup.id,
                    groupAttrs: attrs,
                  },
                },
              });
            }

            return Promise.resolve();
          })
        : []),
    ]);

    setIsLoading(false);
    setModalOpen(false);

    onFinish();
  };

  const EDIT_DAMAGES = { min: 1, max: 200 };

  return (
    <>
      <Modal
        visible={modalOpen}
        title={'Confirm Observation Group Bulk Editing'}
        okText={'Confirm'}
        cancelText={'Cancel'}
        onOk={async () => {
          await handleBulkEdit(values);
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        confirmLoading={isLoading}
        destroyOnClose
      >
        {modalText}
      </Modal>
      <BulkEdit
        records={selected.map(({ id, primaryObservationGroupAttrs, confirmationStatus }) => ({
          id,
          ...primaryObservationGroupAttrs,
          confirmationStatus,
        }))}
        columns={columns}
        onSubmit={handleSubmit}
        disabled={selected.length < EDIT_DAMAGES.min || selected.length > EDIT_DAMAGES.max}
        disabledMessage={
          selected.length < EDIT_DAMAGES.min || selected.length > EDIT_DAMAGES.max
            ? `Select from ${EDIT_DAMAGES.min} to ${EDIT_DAMAGES.max} damages to bulk edit.`
            : ''
        }
      />
    </>
  );
};
