import { useCallback } from 'react';
import { Dropdown } from 'components/ui';
import { BarChartOutlined } from '@ant-design/icons';
import { StyledButton } from '../ImageViewer.style';

import { useApolloContext } from 'utils/apollo';
import { PapiGqlImageFilter, useGetImageFiltersQuery } from 'types/papi-graphql';
import { useAccountContext } from 'utils/account/AccountContext';

const NO_FILTER = 'no-filter';

type FiltersProps = { onChange: (data: any) => void; value?: string };

export const Filters: React.FunctionComponent<FiltersProps> = ({ onChange, value = NO_FILTER }) => {
  const { papiClient } = useApolloContext();
  const { hasReleaseToggle } = useAccountContext();

  const { loading: imageFiltersLoading, data } = useGetImageFiltersQuery({ client: papiClient });
  const imageFilters = data?.imageFilters;

  const hasToggleImageEnhanceToggle = hasReleaseToggle('toggle-image-enhance');
  const hasDefaultImageEnhance = hasReleaseToggle('default-image-enhance');
  const ENHANCE = 'enhance';
  const ORIGINAL = 'original';

  // TODO: when we remove both relese toggles we can remove `filteredImageFilters` and replace its references with `imageFilters`.
  // Currently the enhance and original filters show as an option, but will throw errors if you select them. To be addressed in upcoming work - AN 11/14/23 CRS 11/27/23
  const reworkFiltersForPostProcessing = () => {
    // If we have `default-image-enhance` on: remove 'enhance' so only 'original' is there
    if (hasDefaultImageEnhance) {
      return imageFilters?.filter(filterOption => filterOption && filterOption.id !== ENHANCE);
    }
    // We know `default-image-enhance` is off. If we have `toggle-image-enhance` on: remove 'original' and keep 'enchance'
    if (hasToggleImageEnhanceToggle) {
      return imageFilters?.filter(filterOption => filterOption && filterOption.id !== ORIGINAL);
    }

    // We know both post processing RTs are off: strip out both 'enhance' and 'original'
    return imageFilters?.filter(
      filterOption => filterOption && ![ENHANCE, ORIGINAL].includes(filterOption?.id)
    );
  };

  const filteredImageFilters: PapiGqlImageFilter[] = (
    reworkFiltersForPostProcessing() ?? []
  ).filter((filter): filter is PapiGqlImageFilter => !!filter);

  const defaultImageFilter: PapiGqlImageFilter = { id: NO_FILTER, label: 'No Filter' };

  const filterItems = [defaultImageFilter, ...filteredImageFilters].map(filter => {
    const { id, label } = filter;
    return {
      key: id,
      label: label,
      disabled: id === value,
    };
  });

  const handleClick = useCallback(
    ({ key }) => {
      onChange({ filter: key === NO_FILTER ? null : key });
    },
    [onChange]
  );

  return (
    <Dropdown menu={{ items: filterItems, onClick: handleClick }} trigger={['click']}>
      <StyledButton
        data-testid="image-controls-filter"
        title="Filter"
        onClick={onChange}
        disabled={imageFiltersLoading}
      >
        <BarChartOutlined />
      </StyledButton>
    </Dropdown>
  );
};
