import { useState } from 'react';
import { UserAddOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { Divider, ConfigProvider, SelectProps } from 'antd5';
import { useDebouncedCallback } from 'use-debounce';
import { Modal } from 'components/ui';
import { AddVendorContainer, VendorSelect } from './VendorOrgChooser.style';
import { useVendorOrgRelationshipOptions } from 'utils/organization';

interface Props extends SelectProps {}

export function VendorOrgChooser(props: Props) {
  const { loading, vendorOptions } = useVendorOrgRelationshipOptions();
  const [open, _setOpen] = useState<boolean>(false);

  // small wait to let the antd dropdown animations get outta the way
  const setOpen = useDebouncedCallback((value: boolean) => _setOpen(value), 100);

  return (
    <ConfigProvider renderEmpty={() => <RenderEmpty />}>
      <Modal
        data-testid="add-vendor-modal"
        footer={null}
        onCancel={() => setOpen(false)}
        open={open}
        title="Add Vendor"
      >
        Please contact <a href="mailto:support@skyspecs.com">support@skyspecs.com</a> to add an
        available vendor to your account. Once that relationship has been established, you will be
        able to attach that vendor to your work orders.
      </Modal>

      <VendorSelect
        allowClear={true}
        defaultActiveFirstOption={false}
        dropdownRender={content => (
          <RenderDropdown content={content} setOpen={open => setOpen(open)} />
        )}
        loading={loading}
        menuItemSelectedIcon={<CheckOutlined />}
        options={vendorOptions}
        placeholder="Set Vendor"
        popupMatchSelectWidth={false}
        {...props}
      />
    </ConfigProvider>
  );
}

interface RenderDropdownProps {
  content: React.ReactNode;
  setOpen: (open: boolean) => void;
}

function RenderDropdown({ content, setOpen }: RenderDropdownProps) {
  return (
    <div id="vendor-chooser-dropdown">
      <div
        data-testid="add-vendor"
        // have to use onMouseDown rather than onClick cuz antd
        onMouseDown={() => setOpen(true)}
      >
        <AddVendorContainer>
          <PlusOutlined style={{ marginRight: '4px' }} />
          <span>Add Vendor</span>
        </AddVendorContainer>
      </div>

      <Divider style={{ margin: '4px 0' }} />

      {content}
    </div>
  );
}

function RenderEmpty() {
  return (
    <div>
      <UserAddOutlined style={{ marginRight: '4px' }} />
      <span>No vendors</span>
    </div>
  );
}
