import {
  AtlasGqlGlobalWorkContainerField,
  AtlasGqlTask,
  useGetWorkContainerFieldsQuery,
} from 'types/atlas-graphql';
import { TASK_LEVEL_TYPES } from 'utils/constants';

import { TDisplayColumnData } from 'components/data/types';
import {
  getTaskTypeFiltersFromTasks,
  useMapFieldsToColumns,
} from 'components/data/tasks/customFields';
import { useState } from 'react';
import { snakeCase } from 'lodash';
import { equals } from 'lodash/fp';

/**
 * Hook that is used to responsively manage the columns and fields to be displayed in a Task-level-Task-displaying client-side data table
 * @param setTypesFilter callback to set the value of typesFilter, used within the onFiltersChange callback
 * @returns
 *   fieldColumns: the columns for the currently-visible fields
 *   workContainerFields: field definitions for the currently-visible fields
 *   updateValues: callback that can be fired with the same input parameters, to update the values of fieldColumns and workContainerFields
 *   onFiltersChange: callback to pass to the DataTable in order to properly respond to filter updates
 *   queryLoading: boolean flag indicating if the work container fields query is loading or has completed
 */
export function useFilteredTaskFields(setTypesFilter: Function) {
  // Query custom fields so that we can display them as additional columns
  const workFieldsResult = useGetWorkContainerFieldsQuery();
  const [fieldColumns, setFieldColumns] = useState<(TDisplayColumnData | undefined)[]>([]);
  const [workContainerFields, setWorkContainerFields] =
    useState<AtlasGqlGlobalWorkContainerField[]>();

  const mapFieldsToColumns = useMapFieldsToColumns();

  /**
   * Callback to update the values of fieldColumns and workContainerFields
   * @param typesFilter Array of strings representing the current Work Container Types that are being filtered for
   * @param areTasksArchived boolean to indicate if any of the relevant tasks are archived. This is required to make sure we properly disable the bulk edit fields.
   */
  const updateFilteredTaskFieldsValues = (typesFilter: string[], areTasksArchived: boolean) => {
    // Only update the contents of workContainer fields if we are filtering for a subset of types
    // If no type filters are set (we are displaying all task types) then show no additional fields
    if (typesFilter !== TASK_LEVEL_TYPES) {
      const tempFields =
        (workFieldsResult?.data?.workContainerFields as AtlasGqlGlobalWorkContainerField[]) ?? [];
      // Because workContainerFields may be listed in dependency arrays of useEffects is other components, we only want to set it if the value really has changed.
      if (!equals(tempFields, workContainerFields)) {
        setWorkContainerFields(tempFields);
      }
      // Find all the fields that are configured for all of the currently selected task types
      const filteredFields = tempFields.filter(field => {
        const configuredTypes =
          field.configured && field.configured.map(c => c && c.associatedType.type.toString());
        return typesFilter.every(f => configuredTypes?.includes(f));
      });
      // Take the relevant fields and map get their column definitions
      //@ts-ignore The string - AtlasGqlWorkContainerField mismatch is real but I'm ignoring it for now.
      setFieldColumns(mapFieldsToColumns(filteredFields, true, typesFilter, areTasksArchived));
    }
  };

  /**
   * Callback function to be passed to the Datatable.
   * This handles updating the value of taskTypeFilters when the filters are changed via the type column in the DataTable
   * Usage as a DataTable parameter should look something like this:
   * `onFiltersChange={input => onFiltersChange(input, tabData[currentTab])}`
   * @param filters Filter object, passed in from the DataTable itself
   * @param tasks The current array of tasks displayed in the table. Usually can be derived on a tabbed table using something like `tabData[currentTab]
   * @returns Void. Relevant value is set via setTypesFilter, which is passed in to the call of the overall hook.
   */
  function onFiltersChange(filters: any, tasks: AtlasGqlTask[]) {
    // Search for the 'type' field on the filters
    const typeList: string[] = filters?.type ?? [];

    if (typeList && typeList?.length) {
      // The type list comes in "THIS FORMAT" and we need it in "THIS_FORMAT"
      const formattedTypeList = typeList.map(t => snakeCase(t).toUpperCase());
      setTypesFilter(formattedTypeList);
      return;
    }
    // Default to the filters that can be derived from the current set of data
    const taskTypeFilters = getTaskTypeFiltersFromTasks(tasks);
    setTypesFilter(taskTypeFilters);
  }
  return {
    fieldColumns,
    workContainerFields,
    updateFilteredTaskFieldsValues,
    onFiltersChange,
    queryLoading: workFieldsResult?.loading,
  };
}
