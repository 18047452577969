import { AtlasGqlTask } from 'types/atlas-graphql';
import { ASSET_TYPE_BLADE, ASSET_TYPE_TURBINE } from 'utils/constants';

// AtlasGqlTask is what the queries wiill come back as, but certain task types have additional fields
// that we are interested in. This type helps to get the additional fields we care about and make typescript happy.
type GetTurbinesFromTasksInput = Partial<AtlasGqlTask> & {
  asset?: {
    assetType?: {
      name: string;
    };
    ancestors?: {
      assetType?: {
        name: string;
      };
      id: string;
    }[];
    id: string;
  };
};

export function getTurbinesFromTasks(tasks: GetTurbinesFromTasksInput[]): string[] {
  const result: string[] = [];

  tasks.forEach(task => {
    if (task.asset?.assetType?.name === ASSET_TYPE_BLADE && task.asset?.ancestors) {
      task.asset.ancestors?.forEach(ancestor => {
        if (ancestor.assetType?.name === ASSET_TYPE_TURBINE && !result.includes(ancestor.id)) {
          result.push(ancestor.id);
        }
      });
    } else if (
      task.asset?.assetType?.name === ASSET_TYPE_TURBINE &&
      !result.includes(task.asset.id)
    ) {
      result.push(task.asset.id);
    }
  });

  return result;
}

// AtlasGqlTask is what the queries wiill come back as, but certain task types have additional fields
// that we are interested in. This type helps to get the additional fields we care about and make typescript happy.
type GetTurbinesFromTasksForCreateWOInput = Partial<AtlasGqlTask> & {
  damage?: {
    asset?: {
      ancestors?: {
        assetType: {
          name: string;
        };
        id: number;
      }[];
    };
  };
  blade?: {
    turbine?: {
      id: number;
    };
  };
  turbine?: {
    id: number;
  };
};

export function getTurbinesFromTasksForCreateWO(
  tasks: GetTurbinesFromTasksForCreateWOInput[]
): (number | string)[] {
  const result: number[] = [];

  tasks.forEach(task => {
    if (task?.damage?.asset?.ancestors) {
      task.damage.asset.ancestors.forEach(ancestor => {
        if (ancestor.assetType.name === ASSET_TYPE_TURBINE && !result.includes(ancestor.id)) {
          result.push(ancestor.id);
        }
      });
    } else if (task?.blade?.turbine?.id) {
      if (!result.includes(task.blade.turbine.id)) {
        result.push(task.blade.turbine.id);
      }
    } else if (task.turbine && task.turbine.id) {
      if (!result.includes(task.turbine.id)) {
        result.push(task.turbine.id);
      }
    }
  });

  return result;
}
