import moment from 'moment';
import { DatePicker, Slider, InputNumber, Checkbox } from 'antd';
import { useRef } from 'react';
import { extent } from 'd3-array';
import { get, uniqBy, isArray, isEmpty, isNumber, isNil } from 'lodash';
import { Button } from 'components/ui';
import { DATE_FORMAT } from 'utils/constants';
import { formatDate } from 'utils/format';
import { FilterDropdown } from './FilterForm';
import {
  CustomFilterPopover,
  CustomFilterContainer,
  InputNumberContainer,
  InputNumberLabel,
  Container,
  ListItem,
  Text,
  BoldText,
  NoneText,
} from './filters.style';
import { getSeverityAndCriticalFilter } from 'components/DamageTable2/customFilters/SeverityAndCriticalFilter';
import { AutoFocus } from 'horizon/components/AutoFocus';

const NONE_VALUE = ' ';

// Builds filter options for table filter dropdown
export const getFilter = ({ values } = {}) => {
  return uniqBy(values, 'id')
    .filter(Boolean)
    .map(value => ({
      text: value.label,
      value: value.id || ' ',
    }));
};

// builds onFilter functions for client-side table filtering
const onFilterString = (key, exactMatch) => (filter, record) => {
  const search = filter.toString().toLowerCase();
  const string = key instanceof Array ? key.map(k => get(record, k)) : get(record, key);
  const value = String(string).toLowerCase();

  return exactMatch ? search === value : search.includes(value) || value.includes(search);
};

const onFilterArray = (key, filterAttr) => (filter, record) => {
  const search = filter.toString().toLowerCase();
  const value = get(record, key);
  return value
    .map(v => v[filterAttr])
    .join(' ')
    .toLowerCase()
    .includes(search);
};

const onFilterRange = (key, type, includeNoneOption) => (filter, record) => {
  /**
   * This is a short circuit for filters come from column definitions that filter
   * multiple properties. Such filters are applied in the ClientDataTable during
   * rendering. See "Columns that filter multiple properties" in
   * src/components/DataTable/README.md for more info.
   */
  if (filter.hasOwnProperty('key')) {
    return true;
  }

  const value = get(record, key);
  // Account for a none option before we try to check for min/max values
  if (includeNoneOption && filter === NONE_VALUE) {
    return value === '' || value === null || value === undefined;
  }

  let [min, max = Infinity] = filter || [];

  // Filter for arrays returns the array as a concatenated string when prioritizing a table.
  // To combat this we check if the filter is a string and split it appropriately.
  if (typeof filter === 'string') {
    const filterStringToArray = filter.split(',');
    [min, max] = filterStringToArray;
  }

  // if value is a date, format it for comparison
  if (type === 'date') {
    const exclusiveMax = formatDate(moment(max).add(1, 'day'));
    return value >= min && value < exclusiveMax;
  }

  return value >= min && value <= max;
};

export const getOnFilter = ({ key, type, filterAttr, exactMatch, values, includeNoneOption }) => {
  const exact = exactMatch !== null ? exactMatch : Boolean(values) && type === 'string';
  if (type === 'number' || type === 'date') {
    return onFilterRange(key, type, includeNoneOption);
  }
  if (type === 'array') {
    return onFilterArray(key, filterAttr);
  }
  return onFilterString(key, exact);
};

export const OkResetButtons = ({
  confirm,
  clearFilters,
  disabled,
  selectAll,
  handleSelectAll,
  handleSelectInverse,
  hasNoneOption,
  handleToggleNoneOption,
  noneOptionChecked,
  allChecked,
  indeterminate,
}) => (
  <div
    style={{
      borderTop: selectAll || handleSelectInverse || hasNoneOption ? '1px solid #f0f0f0' : 'none',
    }}
  >
    {selectAll && (
      <ListItem onClick={handleSelectAll} checked={allChecked}>
        <Checkbox onChange={handleSelectAll} checked={allChecked} indeterminate={indeterminate} />
        <BoldText>Select All</BoldText>
      </ListItem>
    )}
    {hasNoneOption && (
      <ListItem onClick={handleToggleNoneOption} checked={allChecked}>
        <Checkbox onChange={handleToggleNoneOption} checked={noneOptionChecked} />
        <NoneText />
      </ListItem>
    )}
    {handleSelectInverse && (
      <ListItem>
        <Button
          _version={4}
          onClick={handleSelectInverse}
          size="small"
          type="link"
          title="Remove selected filters and select unselected filters"
        >
          Select Inverse
        </Button>
      </ListItem>
    )}
    <div
      className="ant-table-filter-dropdown-btns"
      style={{ borderTop: selectAll ? 'none' : '1px solid #f0f0f0' }}
    >
      <Button _version={4} type="link" size="small" onClick={clearFilters}>
        Reset
      </Button>
      <Button _version={4} type="primary" size="small" onClick={confirm} disabled={disabled}>
        OK
      </Button>
    </div>
  </div>
);

export const getNumberPickerProps = (values, step, selectedKeys, isNull) => {
  const rawValues = values.map(v => v.label);
  const [rawMin, rawMax] = extent(rawValues);
  const min = rawMin === -Infinity ? undefined : Math.floor(rawMin);
  const max = rawMax === Infinity ? undefined : Math.ceil(rawMax);

  const [[selectedMin = min, selectedMax = max] = []] = selectedKeys || [];

  return {
    min,
    max,
    value: isNull ? [min, max] : [selectedMin, selectedMax],
    step: step || 0.1, // step is sometimes null so can't use default val
  };
};

export const getNumberPicker =
  (values, roundValue, step, includeSlider, includeNoneOption, noneOptionValue) =>
  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const inputRef = useRef(null);
    const noneValue = noneOptionValue ?? NONE_VALUE;
    const isNull = selectedKeys.some(value => value === noneValue);

    const { min, max, value, ...rest } = getNumberPickerProps(values, step, selectedKeys, isNull);

    const handleInputChange = ({ updated: _updated, isMin }) => {
      if (isNaN(_updated)) {
        return;
      }

      const updated =
        roundValue && isNumber(step) && isNumber(_updated)
          ? Math.round(_updated / step) * step
          : _updated;

      if (isMin) {
        setSelectedKeys([[updated, value[1]]]);
      } else {
        setSelectedKeys([[value[0], updated]]);
      }
    };

    const handleConfirm = () => {
      if (isEmpty(selectedKeys) && value !== undefined) {
        setSelectedKeys([[value[0], value[1]]]);
      }
      confirm({ closeDropdown: true });
    };

    const hasValidValues =
      (isNumber(value[0]) || value[0] === noneValue || (isNil(min) && isNil(value[0]))) &&
      (isNumber(value[1]) || value[1] === noneValue || (isNil(max) && isNil(value[1])));

    return (
      <AutoFocus elementToFocus={inputRef}>
        <CustomFilterPopover className="ant-table-filter-dropdown">
          <CustomFilterContainer>
            {includeSlider && (
              <Slider
                range
                // need to send all values as a nested array so the onFilters can handle ranges
                onChange={value => setSelectedKeys([value])}
                min={min}
                max={max}
                value={value}
                disabled={isNull}
                {...rest}
              />
            )}
            <InputNumberContainer>
              <div>
                <InputNumberLabel>Minimum</InputNumberLabel>
                <InputNumber
                  ref={inputRef}
                  placeholder={'Min'}
                  min={min}
                  max={value[1] ? value[1] : max}
                  value={value[0]}
                  onChange={updated => handleInputChange({ updated, isMin: true })}
                  style={{ width: '75px' }}
                  disabled={isNull}
                  {...rest}
                />
              </div>
              <div>
                <InputNumberLabel>Maximum</InputNumberLabel>
                <InputNumber
                  placeholder={'Max'}
                  min={value[0] ? value[0] : min}
                  max={max}
                  value={value[1]}
                  onChange={updated => handleInputChange({ updated, isMin: false })}
                  style={{ width: '75px' }}
                  disabled={isNull}
                  {...rest}
                />
              </div>
            </InputNumberContainer>
          </CustomFilterContainer>
          <OkResetButtons
            confirm={handleConfirm}
            clearFilters={clearFilters}
            disabled={!hasValidValues}
            hasNoneOption={includeNoneOption}
            noneOptionChecked={selectedKeys.includes(noneValue)}
            handleToggleNoneOption={event => {
              if (event.target.checked) {
                setSelectedKeys([noneValue]);
              } else {
                setSelectedKeys(selectedKeys.filter(k => k !== noneValue));
              }
            }}
          />
        </CustomFilterPopover>
      </AutoFocus>
    );
  };

const getDatePicker =
  (values, includeNoneOption, noneOptionValue) =>
  ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => {
    const inputRef = useRef(null);
    const [selectedRange] = selectedKeys || [];
    const noneValue = noneOptionValue ?? NONE_VALUE;
    const isNull = selectedKeys.some(value => value === noneValue);

    const defaultValue =
      isArray(selectedRange) && selectedRange.length ? selectedRange.map(d => moment(d)) : [];
    const currentYear = moment().year();
    const ranges = {
      [currentYear]: getYearRange(currentYear),
      [currentYear - 1]: getYearRange(currentYear - 1),
      [currentYear - 2]: getYearRange(currentYear - 2),
    };

    const handleConfirm = () => {
      confirm({ closeDropdown: true });
    };

    const handleClearFilters = () => {
      clearFilters();
      setSelectedKeys([]);
    };

    return (
      <AutoFocus elementToFocus={inputRef}>
        <CustomFilterPopover className="ant-table-filter-dropdown">
          <CustomFilterContainer>
            <DatePicker.RangePicker
              ref={inputRef}
              onChange={dates => {
                if (dates && dates[0] && dates[1] && dates[0].isSameOrBefore(dates[1])) {
                  // need to send all values as a nested array so the onFilters can handle ranges
                  setSelectedKeys([dates.map(formatDate)]);
                } else {
                  setSelectedKeys([]);
                }
              }}
              dropdownAlign={{
                points: ['tr', 'br'],
              }}
              format={DATE_FORMAT}
              value={defaultValue}
              ranges={ranges}
              disabled={isNull}
            />
          </CustomFilterContainer>
          <OkResetButtons
            confirm={handleConfirm}
            clearFilters={handleClearFilters}
            hasNoneOption={includeNoneOption}
            noneOptionChecked={selectedKeys.includes(noneValue)}
            handleToggleNoneOption={event => {
              if (event.target.checked) {
                setSelectedKeys([noneValue]);
              } else {
                setSelectedKeys(selectedKeys.filter(k => k !== noneValue));
              }
            }}
          />
        </CustomFilterPopover>
      </AutoFocus>
    );
  };

const getDefaultFilterDropdown = initialProps => props => (
  <FilterDropdown {...initialProps} {...props} />
);

const getYearRange = year => [
  moment().startOf('year').year(year),
  moment().endOf('year').year(year),
];

const getSelectAll =
  ({ includeNoneOption, noneOptionValue, ...rest }) =>
  ({ confirm, clearFilters, selectedKeys, setSelectedKeys, filters }) => {
    const inputRef = useRef(null);
    const { value: noneValuefilter } = filters.find(f => f.text === 'none') ?? { value: '' };
    const noneValue = noneOptionValue ?? noneValuefilter;

    const noneOptionChecked = (selectedKeys ?? []).includes(noneValue);
    const indeterminate =
      selectedKeys?.length &&
      selectedKeys.length < filters.length &&
      !selectedKeys.includes(noneValue);
    const allChecked = selectedKeys?.length === filters?.length;

    const handleSelectAll = () => {
      if (allChecked) {
        setSelectedKeys([]);
      } else {
        setSelectedKeys(filters?.map(f => f?.value));
      }
    };

    const handleSelectInverse = () => {
      setSelectedKeys(
        (filters ?? []).filter(f => !selectedKeys.includes(f?.value)).map(({ value }) => value)
      );
    };

    const handleChange = value => {
      const index = selectedKeys.findIndex(k => k === value);

      // handle noneValues specially so we can set the selectedKeys appropriately.
      if (value === noneValue) {
        if (index === -1) {
          return setSelectedKeys([value]);
        } else {
          return setSelectedKeys([]);
        }
      }

      if (index === -1) {
        const filteredSelections = selectedKeys.filter(key => key !== noneValue);
        setSelectedKeys([...filteredSelections, value]);
      } else {
        const selectedClone = [...selectedKeys];
        selectedClone.splice(index, 1);
        setSelectedKeys(selectedClone);
      }
    };

    const handleConfirm = () => {
      confirm({ closeDropdown: true });
    };

    return (
      <AutoFocus elementToFocus={inputRef}>
        <Container>
          {filters.map((f, i) => {
            if (f.text === 'none' || f.text === noneValue) {
              return null;
            }

            return (
              <ListItem
                className="ant-dropdown-menu-item"
                key={f.value}
                onClick={() => handleChange(f.value)}
                checked={selectedKeys?.some(key => key === f.value)}
              >
                <Checkbox
                  ref={i === 0 ? inputRef : undefined}
                  onChange={() => handleChange(f.value)}
                  checked={selectedKeys?.some(key => key === f.value)}
                />
                <Text>{f.text}</Text>
              </ListItem>
            );
          })}
        </Container>
        <OkResetButtons
          confirm={handleConfirm}
          clearFilters={clearFilters}
          selectAll={true}
          indeterminate={indeterminate}
          allChecked={allChecked}
          handleSelectAll={handleSelectAll}
          handleSelectInverse={handleSelectInverse}
          hasNoneOption={includeNoneOption}
          noneOptionChecked={noneOptionChecked}
          handleToggleNoneOption={() => handleChange(noneValue)}
        />
      </AutoFocus>
    );
  };

export const getFilterDropdown = (initialProps = {}) => {
  const {
    values = [],
    type = 'default',
    step,
    roundValue = false,
    includeSlider = true,
    includeNoneOption = false,
    noneOptionValue = ' ',
    definition,
    criticalOnly = false,
  } = initialProps;

  if (definition?.name === 'Severity') {
    return getSeverityAndCriticalFilter(
      values,
      roundValue,
      step,
      includeSlider,
      includeNoneOption,
      noneOptionValue,
      criticalOnly
    );
  }
  if (type === 'number') {
    return getNumberPicker(
      values,
      roundValue,
      step,
      includeSlider,
      includeNoneOption,
      noneOptionValue
    );
  }
  if (type === 'date') {
    return getDatePicker(values, includeNoneOption, noneOptionValue);
  }
  if (type === 'string') {
    return getSelectAll(initialProps);
  }
  // FIXME ARS - hate this, but until I rip out filterProperty this is the easiest way to keep existing functionality
  if (type === 'default') {
    return getDefaultFilterDropdown(initialProps);
  }
};

// Limit filter options/ranges when filtering damage subsets client-side
export const limitFiltersFromData = (col, data = []) => {
  if (!data || !data.length || !col.type) return col;

  const config = {
    type: col.type,
    values: data.map(d => ({
      id: get(d, col.filterProperty, ''),
      label: get(d, col.filterProperty, ''),
    })),
  };

  return {
    ...col,
    filters: getFilter(config),
    filterDropdown: getFilterDropdown(config),
  };
};
