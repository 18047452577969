import { useEffect, useState } from 'react';
import { getStrippedUniqueIdLabel } from 'horizon/components/Assets/AssetDetails/helpers';
import {
  useGetInitialAssetForAssetPickerLazyQuery,
  AtlasGqlAsset,
  useGetAssetTypesMinimalQuery,
} from 'types/atlas-graphql';
import { AssetPickerItem } from './AssetPickerItem';
import { AssetHierarchyItem } from './types';
import { useFeatures } from 'utils/features';

/**
 *  parentAsset (optional) - "starting point", when provided,
 *  the component will facilitate choosing any descendant
 *   of this asset
 *
 *  assetTypeId (optional) - "end point", when provided, the component
 *  will only return a value to the form when the selected
 *  asset is of this type
 *
 * shouldIncludeAssetTypeSelector boolean (optional) - when `true`,
 * asset type selectors will always be included, regardless of the account's
 * feature flags
 *
 * filterAssetTypeOption function (optional) - when provided, this function will
 * be used to determine if the asset type option should be shown
 */
type AssetPickerProps = {
  assetTypeId?: string;
  disableAll?: boolean;
  disableSubAssetUntilAssetTypeIsSelected?: boolean;
  filterAssetTypeOption?: (asset: AtlasGqlAsset, parentAsset?: AssetHierarchyItem) => boolean;
  initialAsset?: AtlasGqlAsset | null;
  onChange?: (value?: AssetHierarchyItem) => void;
  parentAsset?: AtlasGqlAsset;
  shouldIncludeAssetTypeSelector?: boolean;
  value?: AssetHierarchyItem;
};

export const AssetPicker: React.FunctionComponent<AssetPickerProps> = ({
  assetTypeId,
  disableAll = false,
  disableSubAssetUntilAssetTypeIsSelected = false,
  filterAssetTypeOption,
  initialAsset,
  onChange,
  parentAsset,
  shouldIncludeAssetTypeSelector: _shouldIncludeAssetTypeSelector = false,
  value,
}) => {
  const { FLEET_MANAGEMENT } = useFeatures().features;
  const shouldIncludeAssetTypeSelector = _shouldIncludeAssetTypeSelector || FLEET_MANAGEMENT;
  const [assetHierarchy, setAssetHierarchy] = useState<AssetHierarchyItem[]>(
    parentAsset
      ? [
          {
            assetId: parentAsset.id,
            assetTypeId: parentAsset?.assetType?.id,
            assetTypeName: parentAsset?.assetType?.name,
            name: getStrippedUniqueIdLabel(parentAsset),
          },
        ]
      : []
  );
  const [isMounted, setIsMounted] = useState<boolean>(true);

  const [getInitialAsset, { data }]: any = useGetInitialAssetForAssetPickerLazyQuery();

  const setInitialAsset = (asset: AtlasGqlAsset) => {
    const hierarchy = [
      {
        assetId: asset.id,
        assetTypeId: asset.assetType?.id,
        assetTypeName: asset.assetType?.name,
        name: getStrippedUniqueIdLabel(asset),
      },
    ].concat(
      asset.ancestors?.map((ancestor: any) => ({
        assetId: ancestor.id,
        assetTypeId: ancestor.assetType?.id,
        assetTypeName: ancestor.assetType?.name,
        name: getStrippedUniqueIdLabel(ancestor),
      })) ?? []
    );

    if (hierarchy) setAssetHierarchy(hierarchy.reverse());
  };

  useEffect(() => {
    setIsMounted(true);
    if (value) {
      if (initialAsset?.id === value.assetId && initialAsset?.ancestors) {
        setInitialAsset(initialAsset);
      } else {
        getInitialAsset({ variables: { id: value.assetId } });
      }
    }
    return () => {
      setIsMounted(false);
    };
  }, [value]);

  useEffect(() => {
    // When creating an upload from a Task Detail we're disabling our Select's in the AssetPicker because we want the asset to match what's in the task. We still need to set our initial assetTypeId though in order to get our type dropdown to populate.
    if (disableAll && initialAsset && onChange) {
      onChange({
        assetId: initialAsset.id,
        assetTypeId: initialAsset.assetType?.id,
        assetTypeName: initialAsset.assetType?.name,
        name: getStrippedUniqueIdLabel(initialAsset),
      });
    }
  }, [disableAll]);

  useEffect(() => {
    if (data?.assetWithMetadata?.ancestors) {
      const { assetWithMetadata } = data;
      setInitialAsset(assetWithMetadata as AtlasGqlAsset);
      if (onChange) {
        onChange({
          assetId: assetWithMetadata.id,
          assetTypeId: assetWithMetadata.assetType?.id,
          assetTypeName: assetWithMetadata.assetType?.name,
          // @ts-ignore The types of ancestors don't match up perfectly here.
          name: getStrippedUniqueIdLabel(assetWithMetadata),
        });
      }
    }
  }, [data]);

  const { data: assetTypesData } = useGetAssetTypesMinimalQuery();
  const assetTypes = assetTypesData?.assetTypes ?? [];

  const siteType: { id: string; name: string } | undefined = assetTypes.find(
    ({ name }: { name: string }) => name === 'Site'
  );

  const turbineType: { id: string; name: string } | undefined = assetTypes.find(
    ({ name }: { name: string }) => name === 'Turbine'
  );

  const handleSelectAssetType = (index: number): void => {
    // If we've disabled selecting assets we don't want to reset the hierarchy.
    // For Generic Assets this can lead to the component type being selected, but the asset itself deselected.
    if (disableAll) return;
    setAssetHierarchy([...assetHierarchy.slice(0, index + 1)]);
    // Asset type changes only occur in fleet management.
    // Asset changes occur in blade management workflows.
    // Only trigger an asset type change for fleet management or where
    // the type selector is explicitly included with `shouldIncludeAssetTypeSelector`
    if (onChange && shouldIncludeAssetTypeSelector) {
      onChange();
    }
  };

  const handleSelectAsset = (asset: AssetHierarchyItem, index: number): void => {
    setAssetHierarchy([...assetHierarchy.slice(0, index), asset]);

    if (onChange) {
      onChange(asset);
    }
  };

  return (
    <>
      {/* If parentAssetId is provided, start by selecting any child of that asset */}
      {parentAsset && (
        <AssetPickerItem
          defaultValue={parentAsset.id}
          parentId={parentAsset.parent?.id}
          assetType={parentAsset.assetType?.id}
          onChange={(asset: AssetHierarchyItem) => handleSelectAsset(asset, 0)}
          disabled={disableAll}
          testId="parent"
        />
      )}
      {/* If parentAssetId is not provided, start by selecting a site */}
      {!parentAsset && siteType && (
        <AssetPickerItem
          assetType={siteType}
          defaultValue={assetHierarchy[0]?.assetId}
          onChange={(asset: AssetHierarchyItem) => handleSelectAsset(asset, 0)}
          disabled={disableAll}
          testId="site"
        />
      )}
      {assetHierarchy.map((asset, i) => (
        <AssetPickerItem
          assetType={!shouldIncludeAssetTypeSelector ? turbineType : undefined}
          defaultValue={assetHierarchy[i + 1]?.assetId}
          disabled={disableAll}
          disableSubAssetUntilAssetTypeIsSelected={disableSubAssetUntilAssetTypeIsSelected}
          filterAssetTypeOption={filterAssetTypeOption}
          key={i}
          onChange={(asset: AssetHierarchyItem) => handleSelectAsset(asset, i + 1)}
          onChangeAssetType={() => handleSelectAssetType(i)}
          parentHierarchyAsset={asset}
          parentId={asset.assetId}
          testId={`idx-${i}`}
        />
      ))}
    </>
  );
};
