import { Select, message } from 'antd';
import { sortBy } from 'lodash';

import { ClearIcon, SuffixIcon } from 'components/SelectChooser/SelectChooser.style';
import { filterOption } from '../helpers';
import { useAssetChooserState } from '../AssetChooser/useAssetChooserState';
import { useGetSitesMinimalQuery } from 'types/atlas-graphql';

interface ILocationChooser {
  value: string | string[] | undefined;
  onChange: (locationIds: string[]) => void;
  onClear?: () => void;
  disabled: boolean;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
}

export const LocationChooser: React.FunctionComponent<ILocationChooser> = ({
  value,
  onChange,
  onClear,
  disabled,
  mode,
  placeholder = 'Select Site...',
}) => {
  const { handleChange, setVisible, chooserState } = useAssetChooserState({ onChange, value });
  const { data, loading } = useGetSitesMinimalQuery({
    onError: error => {
      message.error(error.message, 5);
    },
  });

  const locations = sortBy(data?.sites?.items, ['name']) ?? [];

  return (
    <Select
      id="location-chooser-dropdown"
      placeholder={placeholder}
      loading={loading}
      allowClear={true}
      showSearch={true}
      filterOption={filterOption}
      virtual={false}
      value={loading ? undefined : chooserState}
      onChange={handleChange}
      onDropdownVisibleChange={setVisible}
      onClear={onClear}
      showArrow={chooserState ? false : true}
      clearIcon={<ClearIcon />}
      suffixIcon={<SuffixIcon />}
      disabled={disabled}
      mode={mode}
    >
      {locations.map(location => (
        <Select.Option key={location.id} className="location-chooser-option" value={location.id}>
          {location.name}
        </Select.Option>
      ))}
    </Select>
  );
};
