import {
  AtlasGqlWorkContainerFieldType,
  AtlasGqlWorkContainerTypeOption,
} from 'types/atlas-graphql';
import { ConfiguredModified, GlobalWorkContainerFieldModified } from './types';

// we're faking it
export const descriptionField: GlobalWorkContainerFieldModified = {
  id: null,
  name: 'Description',
  slug: 'description',
  standard: false,
  type: AtlasGqlWorkContainerFieldType.Text,
  configured: [
    {
      associatedType: {
        type: AtlasGqlWorkContainerTypeOption.RepairTask,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: AtlasGqlWorkContainerTypeOption.InspectTask,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: AtlasGqlWorkContainerTypeOption.OtherTask,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: AtlasGqlWorkContainerTypeOption.InternalBladeInspectionTask,
      },
      displayOrder: -1,
    },
    {
      associatedType: {
        type: AtlasGqlWorkContainerTypeOption.InspectionTask,
      },
      displayOrder: -1,
    },
  ] as ConfiguredModified[],
};
