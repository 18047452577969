import styled from 'styled-components';
import COLOR from 'utils/color/definitions';

/**
 * `$isScrolledTo{X} props are used to add box-shadow to make it clear to the user
 * that there is more to see.
 */
export const PlotContainer = styled.div<{
  $isScrolledToBottom: boolean;
  $isScrolledToLeft: boolean;
  $isScrolledToRight: boolean;
}>`
  background: ${COLOR.WHITE};
  max-height: 0;
  opacity: 0;
  position: relative;
  transition: all 0.5s ease-in-out;
  width: 100%;

  &.open {
    height: min-content;
    max-height: 70vh;
    opacity: 1;
    overflow: auto;
  }

  ${({ $isScrolledToBottom, $isScrolledToLeft, $isScrolledToRight }) => {
    const spread: number = !$isScrolledToLeft && !$isScrolledToRight ? 5 : 0;
    const xOffset: number =
      !$isScrolledToLeft && !$isScrolledToRight
        ? 0
        : !$isScrolledToLeft
          ? 5
          : !$isScrolledToRight
            ? -5
            : 0;

    const yOffset: number = !$isScrolledToBottom ? (spread === 0 ? -5 : 0) : 10;

    const boxShadow = `inset ${xOffset}px ${yOffset}px 5px ${spread}px rgba(0, 0, 0, 0.2) !important`;

    return `
        -webkit-box-shadow: ${boxShadow};
        -moz-box-shadow: ${boxShadow};
        box-shadow: ${boxShadow};
        `;
  }}
`;

/**
 * `$isScrolledTo{X} props are used to add box-shadow to make it clear to the user
 * that there is more to see.
 */
export const PlotHeader = styled.div<{ $isScrolledToTop: boolean }>`
  align-items: center;
  background: ${COLOR.WHITE};
  box-shadow: 0px 2px 1px 0px #00000033;
  display: flex;
  height: 64px;
  justify-content: space-between;
  left: 0;
  padding: 1rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  > div:first-of-type {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
  }

  .ant-select {
    margin-left: 0.5rem;
    width: 480px;
  }

  button {
    border: none;
  }

  ${({ $isScrolledToTop }) =>
    !$isScrolledToTop &&
    `
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  `}
`;

export const PlotGrid = styled.div<{
  $isScrolledToLeft: boolean;
  $isScrolledToRight: boolean;
}>`
  display: flex;
  flex-direction: row;
  min-width: 1200px;
  position: relative;
  padding-top: 1rem;

  > div:has(svg) {
    width: 100%;

    svg {
      mix-blend-mode: multiply;
      padding-left: 0.5rem;
      padding-right: 1rem;

      max-height: 200px;
      transition: all 0.5s ease-in-out;

      &.hidden {
        max-height: 0;
        opacity: 0;
      }

      .brush {
        rect.selection {
          fill: none;
          stroke: none;
        }

        path {
          fill: none;
          stroke: ${COLOR.TEAL};
          stroke-width: 2;
          stroke-linejoin: bevel;
        }

        text {
          font-size: 10px;
        }
      }
    }
  }

  > button {
    position: sticky;
    right: 0.5rem;
    top: calc(100% - 2.5rem);
  }
`;
