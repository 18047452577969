import { snakeCase } from 'lodash';

import { TQueryFilter } from 'components/DataTable/types';
import { TGenerateDamageTableFiltersParams } from './types';

export function generateDamageTableFilters({
  schemaId,
  critical,
  assetIds,
  assetTypes,
  siteIds,
  turbineIds,
  damageIds,
}: TGenerateDamageTableFiltersParams): TQueryFilter[] {
  const initialFilter: TQueryFilter[] = [];

  if (schemaId) {
    initialFilter.push({
      key: 'schemaId',
      values: [schemaId],
    });
  }

  if (damageIds) {
    initialFilter.push({
      key: 'id',
      values: damageIds,
    });
  }

  if (assetIds) {
    initialFilter.push({
      key: 'assetId',
      values: assetIds,
    });
  }

  if (assetTypes) {
    initialFilter.push({
      key: 'assetType',
      values: assetTypes.map(({ name }) => snakeCase(name.toLowerCase())),
    });
  }

  if (siteIds) {
    initialFilter.push({
      key: 'site',
      values: siteIds,
    });
  }

  if (turbineIds) {
    initialFilter.push({
      key: 'turbineId',
      values: turbineIds,
    });
  }

  if (critical) {
    initialFilter.push({
      key: 'critical',
      values: [critical.toString()],
    });
  }

  return initialFilter;
}
