import {
  AtlasGqlWorkContainerFieldType,
  AtlasGqlWorkContainerTypeOption,
} from './../../../../types/atlas-graphql';
import { ConfiguredModified, GlobalWorkContainerFieldModified } from './types';

// we're faking it
export const forOrganizationField: GlobalWorkContainerFieldModified = {
  id: null,
  name: 'For Organization',
  slug: 'for_organization',
  standard: false,
  type: AtlasGqlWorkContainerFieldType.Select,
  configured: [
    {
      associatedType: {
        type: AtlasGqlWorkContainerTypeOption.InspectionTask,
      },
      // We want this field to be last, essentially.
      displayOrder: 1000,
    },
  ] as ConfiguredModified[],
};
