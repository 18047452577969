import { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Modal } from 'antd';
import _isEqual from 'lodash/isEqual';
import { Button, Dropdown } from 'components/ui';
import { DataTableContext } from '../index';
import { ColumnPicker } from './ColumnPicker';
import { columnPickerStorage } from './ColumnPicker/helpers/storage';
import { MultiSorter } from './MultiSorter';
import { multiSorterStorage } from './MultiSorter/helpers/storage';
import { formatColumns } from './ColumnPicker/helpers/formatColumns';
import { TExtensionColumn, TSortableColumn, TMultiSortColumn } from './types';
import { TDropdownAction, TDataTableStorageKey } from 'components/DataTable/types';
import { TDisplayColumnData } from 'components/data/types';

/**
 * Legacy component to be replaced by the release-toggled Views.tsx. Any future changes other than
 * critical bug fixes should be made there.
 */

interface IExtensionsProps {
  additionalDropdownActions: TDropdownAction[];
  columnDefs: TDisplayColumnData[];
  isPrioritizing?: boolean;
  showColumnPicker: boolean;
  showMultiSorter: boolean;
  storageKey?: TDataTableStorageKey;
}

export const ExtensionsOLD: React.FunctionComponent<IExtensionsProps> = ({
  additionalDropdownActions = [],
  columnDefs,
  isPrioritizing,
  showColumnPicker,
  showMultiSorter,
  storageKey,
}) => {
  const [showColumnPickerModal, setShowColumnPickerModal] = useState(false);
  const [showMultiSorterModal, setShowMultiSorterModal] = useState(false);
  const columnPickerStorageManager = columnPickerStorage({ storageKey: storageKey?.COLUMN_PICKER });
  const multiSorterStorageManager = multiSorterStorage({ storageKey: storageKey?.MULTI_SORT });

  const { updateCustomizedColumnSet, updateMultiSortDef, multiSortDef, customizedColumnSet } =
    useContext(DataTableContext) || {};

  const reduceMultiSortSelection = useCallback(
    (sortedColumns: TMultiSortColumn[]) =>
      sortedColumns.reduce((acc: TSortableColumn[], col: TMultiSortColumn) => {
        const { key, sort } = col;
        const columnDef = key && columnDefs.find(columnDef => columnDef.key === key);
        if (columnDef) {
          return [...acc, { column: columnDef, sortDirection: sort }];
        }
        return acc;
      }, []),
    [columnDefs]
  );

  const updateSort = useCallback(
    sort => {
      const sortDef = reduceMultiSortSelection(sort ?? []);
      if (sortDef.length) {
        updateMultiSortDef(sortDef);
        multiSorterStorageManager.set(sort);
      } else {
        updateMultiSortDef(undefined);
        multiSorterStorageManager.remove();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reduceMultiSortSelection, updateMultiSortDef]
  );

  function shouldUpdateColumns(
    savedColumns: TDisplayColumnData[],
    incomingColumns: TExtensionColumn[]
  ) {
    const formattedCols: TExtensionColumn[] = formatColumns(savedColumns);
    return !_isEqual(formattedCols, incomingColumns);
  }

  const mapColumns = useCallback(
    savedColumns => {
      return savedColumns.map((column: TExtensionColumn) => {
        const fullCol = columnDefs.find(implementation => {
          const key = implementation.key ? 'key' : 'dataIndex';
          return implementation[key] === column.key;
        });
        return {
          ...fullCol,
          visible: column.visible,
        };
      });
    },
    [columnDefs]
  );

  const updateCustomCols = useCallback(
    customCols => {
      if (customCols && shouldUpdateColumns(columnDefs, customCols)) {
        const formattedCols = mapColumns(customCols);
        updateCustomizedColumnSet(formattedCols);
        columnPickerStorageManager.set(
          customCols.map((val: any) => {
            return { key: val.key, visible: val.visible };
          })
        );
      } else {
        updateCustomizedColumnSet(undefined);
        columnPickerStorageManager.remove();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnDefs, mapColumns, updateCustomizedColumnSet]
  );

  useEffect(
    () => {
      function updateSavedColumns() {
        const savedColumns = columnPickerStorageManager.get();
        updateCustomCols(savedColumns);
      }

      if (showColumnPicker) {
        updateSavedColumns();
      }
    },
    [updateCustomCols] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      function updateSavedSort() {
        const savedSort = multiSorterStorageManager.get();
        updateSort(savedSort);
      }

      if (showMultiSorter) {
        updateSavedSort();
      }
    },
    [updateSort] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Typescript gets upset with expecting a MouseEvent type input based on the definition of
  // onCancel's input on a Modal. So we optionally allow any for the input. But we really expect it to have orderedColumns.
  function handleCloseColumnPickerModal(input: { orderedColumns: TExtensionColumn[] } | any) {
    const { orderedColumns } = input || {};
    if (orderedColumns) {
      updateCustomCols(orderedColumns);
    }
    setShowColumnPickerModal(false);
  }

  // Typescript gets upset with expecting a MouseEvent type input based on the definition of
  // onCancel's input on a Modal. So we optionally allow any for the input. But we really expect it to have sortedColumns.
  function handleCloseMultiSorterModal(input: { sortedColumns: TExtensionColumn[] } | any) {
    const { sortedColumns } = input || {};
    if (sortedColumns) {
      updateSort(sortedColumns);
    }

    setShowMultiSorterModal(false);
  }

  function renderMenu() {
    return (
      <Menu>
        {showColumnPicker && (
          <Menu.Item
            key={0}
            onClick={() => setShowColumnPickerModal(true)}
            disabled={isPrioritizing}
          >
            Customize Columns
          </Menu.Item>
        )}
        {showMultiSorter && (
          <Menu.Item key={1} onClick={() => setShowMultiSorterModal(true)}>
            Multiple Sort
          </Menu.Item>
        )}
        {additionalDropdownActions.map(({ content, onClick, disabled, hoverable = true }, i) => {
          const key = i + (showColumnPicker ? 1 : 0) + (showMultiSorter ? 1 : 0);
          return hoverable ? (
            <Menu.Item key={key} onClick={onClick} disabled={disabled}>
              {content}
            </Menu.Item>
          ) : (
            <Menu.Item className="not-hoverable" key={key} onClick={onClick} disabled={disabled}>
              {content}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  return (
    <Fragment>
      <Dropdown _version={4} trigger={['click']} placement="bottomLeft" overlay={renderMenu()}>
        <Button _version={4} data-testid="datatable-actions-button">
          Actions <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        closable={false}
        title="Customize Columns"
        onCancel={handleCloseColumnPickerModal}
        visible={showColumnPickerModal}
        destroyOnClose
        footer={null}
      >
        <ColumnPicker
          columns={columnDefs}
          customizedColumnSet={customizedColumnSet}
          onClose={handleCloseColumnPickerModal}
        />
      </Modal>
      <Modal
        closable={false}
        title="Multiple Sort"
        onCancel={handleCloseMultiSorterModal}
        visible={showMultiSorterModal}
        destroyOnClose
        footer={null}
      >
        <MultiSorter
          columnDefs={columnDefs}
          multiSortDef={multiSortDef}
          onClose={handleCloseMultiSorterModal}
        />
      </Modal>
    </Fragment>
  );
};
