import { memo } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { TabPaneProps } from 'antd/lib/tabs';
import { TabBarExtraContent } from 'rc-tabs/lib/interface';

const PageHeaderWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const Detail = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  padding: 1rem 2em 0 2rem;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? '1rem' : '0')};
`;

const MainContent = styled.div`
  flex: 1;
`;

const StyledTabs = styled(Tabs)`
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  &.ant-tabs {
    padding-left: 1rem;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }

  .ant-tabs-nav {
    margin-bottom: 1px;
  }
`;

export interface PageHeaderProps {
  content?: React.ReactNode;
  extraContent?: React.ReactNode;
  onTabChange?: (tabKey: string) => void;
  tabActiveKey?: string;
  tabBarExtraContent?: TabBarExtraContent;
  tabList?: TabPaneProps[];
}

const PageHeader = memo(
  ({
    content,
    extraContent,
    onTabChange,
    tabActiveKey,
    tabBarExtraContent,
    tabList,
  }: PageHeaderProps) => {
    const onChange = (key: string) => {
      if (onTabChange) {
        onTabChange(key);
      }
    };

    return (
      <PageHeaderWrapper id="page-header-component" data-testid="page-header">
        {(content || extraContent) && (
          <Detail paddingBottom={!tabList} data-testid="page-header-detail">
            <MainContent data-testid="page-header-main-content">{content}</MainContent>
            {extraContent}
          </Detail>
        )}
        {tabList && !!tabList.length && (
          <StyledTabs
            activeKey={tabActiveKey}
            tabBarStyle={{ marginBottom: '0' }}
            onChange={onChange}
            tabBarExtraContent={tabBarExtraContent}
          >
            {tabList.map(item => {
              const key = (item as any).key ?? item.id ?? item.tabKey;
              return <Tabs.TabPane className={`testid-tab-pane-${key}`} key={key} {...item} />;
            })}
          </StyledTabs>
        )}
      </PageHeaderWrapper>
    );
  }
);

export default PageHeader;
