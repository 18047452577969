import { useState, FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'components/ui';
import { SelectableAction, useSelectableRecords } from 'utils/selectable';
import { TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import DataTable from 'components/DataTable';
import { name, status, make, model, location } from 'components/data/turbines';
import { LocationChooser } from 'components/choosers';
import {
  AtlasGqlGetTurbineListChooserQuery,
  useGetTurbineListChooserLazyQuery,
} from 'types/atlas-graphql';
import { notEmpty } from 'utils/types';
import { Empty } from 'antd5';

const Control = styled.div`
  .ant-select {
    min-width: 200px;
  }
`;

const columns = [name, location, status, make, model];

interface BulkTurbineChooserProps {
  onChange: (turbines: any[]) => void;
}

const BulkTurbineChooser: FC<BulkTurbineChooserProps> = ({ onChange }) => {
  const [locationId, setLocationId] = useState<string | undefined>(undefined);
  const [turbines, setTurbines] = useState<AtlasGqlGetTurbineListChooserQuery['turbines']>([]);
  const { selected, rowSelection, onClearSelection } = useSelectableRecords();
  const [getTurbines, { loading }] = useGetTurbineListChooserLazyQuery();

  const handleAdd = useCallback(() => onChange(selected), [selected]);

  const handleLocationChange = useCallback((value: string | string[]) => {
    setLocationId(value instanceof Array ? value[0] : value);
  }, []);

  useEffect(() => {
    if (locationId) {
      getTurbines({
        variables: { filterBy: [{ key: 'siteId', values: [locationId] }] },
        onCompleted(data) {
          setTurbines(data.turbines?.filter(notEmpty) ?? []);
        },
      });
    } else {
      setTurbines([]);
    }
  }, [locationId, getTurbines]);

  return (
    <DataTable
      loading={loading}
      scrollWidth={TABLE_SCROLL_WIDTH_SMALL}
      locale={{
        emptyText: (
          <Empty
            description={
              locationId && !turbines?.length && !loading ? 'No turbines found' : 'Select a site...'
            }
          />
        ),
      }}
      size="small"
      columns={columns}
      data={turbines ?? undefined}
      rowSelection={rowSelection}
      controls={
        <Control>
          <LocationChooser value={locationId} onChange={handleLocationChange} disabled={loading} />
        </Control>
      }
      actions={
        <SelectableAction selected={selected} onClear={onClearSelection}>
          <Button type="primary" disabled={!selected.length} onClick={handleAdd}>
            Add
          </Button>
        </SelectableAction>
      }
    />
  );
};

export default BulkTurbineChooser;
