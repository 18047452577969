import { ApolloError } from '@apollo/client/errors';
import { AtlasGqlGetTaskListFiltersQuery, useGetTaskListFiltersQuery } from 'types/atlas-graphql';

interface UseTaskListFiltersArgs {
  skip?: boolean;
}

interface UseTaskListFilters {
  filters: NonNullable<AtlasGqlGetTaskListFiltersQuery['taskListFilters']>;
  loading: boolean;
  error?: ApolloError;
}

export const useTaskListFilters = (args?: UseTaskListFiltersArgs): UseTaskListFilters => {
  const { data, loading, error } = useGetTaskListFiltersQuery({
    skip: args?.skip ?? false,
  });

  const filters = {
    vendorOrganization: data?.taskListFilters?.vendorOrganization ?? [],
  };

  return {
    filters,
    loading,
    error,
  };
};
