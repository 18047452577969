import { AtlasGqlGlobalWorkContainerField } from 'types/atlas-graphql';
import { getFieldValue } from 'utils/useHeaderFields';

interface SortFieldsArgs {
  allFields: Pick<AtlasGqlGlobalWorkContainerField, 'id' | 'slug' | 'type'>[];
  formValues: any;
  standardFieldKeys: Record<string, any>;
  uneditableFields: Record<string, any>;
}

export function sortFields({
  allFields,
  formValues,
  standardFieldKeys,
  uneditableFields,
}: SortFieldsArgs) {
  return allFields.reduce<{ standard: Record<string, any>; custom: Record<string, any> }>(
    (acc, field) => {
      if (
        formValues[field.slug] &&
        standardFieldKeys[field.slug] &&
        !Boolean(uneditableFields[field.slug])
      ) {
        acc.standard[standardFieldKeys[field.slug].dataIndex] = formValues[field.slug];
      } else if (
        formValues[field.slug] &&
        !standardFieldKeys[field.slug] &&
        !Boolean(uneditableFields[field.slug])
      ) {
        acc.custom[field.id] = getFieldValue(
          { value: formValues[field.slug] },
          { type: field.type }
        );
      }

      return acc;
    },
    { standard: {}, custom: {} }
  );
}
