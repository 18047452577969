// initialize bugsnag ASAP, before other imports
import Bugsnag from '@bugsnag/js';
// This file needs the react import
import * as React from 'react';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import LogRocket from 'logrocket';
import { User } from '@auth0/auth0-react';
import { storage } from './storage';
import { isLocal, isProdReview, isStage, APP_VERSION, isStageReview } from './env';

// these errors are reported a lot, but typically there is no action to take on
// our part. ignore these to reduce the noise to bugsnag.
const errorsToIgnore = [
  'Failed to update a ServiceWorker for scope',
  'ResizeObserver loop completed with undelivered notifications',
];

const MODE = {
  local: 'local',
  stage: 'stage',
  stageReview: 'stage-review',
  production: 'production',
  prodReview: 'prod-review',
};

let ErrorBoundary: BugsnagErrorBoundary = ({ children }: { children: React.ReactElement }) =>
  children;

let releaseStage: string;
if (isLocal) {
  releaseStage = MODE.local;
} else if (isStage) {
  releaseStage = MODE.stage;
} else if (isStageReview) {
  releaseStage = MODE.stageReview;
} else if (isProdReview) {
  releaseStage = MODE.prodReview;
} else {
  releaseStage = MODE.production;
}

/**
 * To enable units tests to operate normally, we try/catch JSDOM issues with bugsnag.
 * Related issues:
 *   - https://github.com/facebook/jest/issues/1909
 *   - https://github.com/bugsnag/bugsnag-js/issues/452
 */
try {
  Bugsnag.start({
    apiKey: '60de0015873d1e2ecc355521123f6bdf',
    appVersion: APP_VERSION,
    enabledReleaseStages: [MODE.stage, MODE.stageReview, MODE.production, MODE.prodReview],
    plugins: [new BugsnagPluginReact()],
    releaseStage,
    onError(event) {
      if (isLocal) {
        return false;
      }

      const customerId = storage.getItem('customerId');
      event.addMetadata('Horizon', { customerId });

      // Addn LogRocket session URL to any bugsnag issues
      if (releaseStage === MODE.production) {
        event.addMetadata('LogRocket', {
          sessionURL: LogRocket.sessionURL,
        });
      }
    },
  });

  ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? ErrorBoundary;
} catch (error) {
  console.error(error.message);
}

const setBugsnagUser = ({ email, sub }: User) => Bugsnag.setUser(sub, email);

const notifyBugsnag = (error: Error | string) => {
  const message = typeof error === 'string' ? error : error.message;

  if (releaseStage !== MODE.local) {
    if (errorsToIgnore.some(e => message.includes(e))) {
      console.warn('Not reporting to bugsnag:', error);
      return;
    }

    Bugsnag.notify(error);
  } else {
    console.info('[message for bugsnag]:', error);
  }
};

export { ErrorBoundary, setBugsnagUser, notifyBugsnag };
