import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Select, Progress } from 'antd5';
import { DownOutlined, FormatPainterFilled } from '@ant-design/icons';
import { groupBy, isEqual, isNil } from 'lodash';
import { DataTableContext } from 'components/DataTable';
import { getFilterBy } from 'components/DataTable/paging';
import { PlotContainer, PlotGrid, PlotHeader } from './BladeDamagePlot.style';
import {
  AtlasGqlBlade,
  AtlasGqlHorizonDamage,
  useGetBladeShapeQuery,
  useGetBladeWithShapeQuery,
  useGetHorizonDamagesForPlotQuery,
} from 'types/atlas-graphql';
import { useIsInView } from 'utils/hooks';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import { BladeDamagePlot } from './BladeDamagePlot';
import COLORS from 'utils/color/definitions';
import { Button, Tooltip } from 'components/ui';
import { PlotStyleOptionsModal } from './PlotStyleOptionsModal/PlotStyleOptionsModal';
import { ColorCodingMap, getColorCodingMap, severityColorCodingMap } from './pointUtils';
import { ColorCodingLegend } from './ColorCodingLegend';
import { AreaSelectionIcon } from './AreaSelectionIcon';
import { UndoAreaSelectionIcon } from './UndoAreaSelectionIcon';

const PAGE_SIZE = 100;

export enum BladeSide {
  LeadingEdge = 'Leading Edge',
  PressureSide = 'Pressure Side',
  SuctionSide = 'Suction Side',
  TrailingEdge = 'Trailing Edge',
}
const BLADE_SIDES = Object.values(BladeSide);

export enum ColorCodingAttribute {
  Severity = 'Severity',
  Type = 'Type',
  OpSeverity = 'OpSeverity',
}
export type PlotStyleState = {
  modalOpen: boolean;
  colorCodingEnabled: boolean;
  colorCodingAttribute: ColorCodingAttribute;
};
const plotStyleStateReducer = (
  prevState: PlotStyleState,
  incomingState: PlotStyleState
): PlotStyleState => ({ ...prevState, ...incomingState });

type BladeDamagePlotContainerProps = {
  isOpen: boolean;
  onClose: () => void;
  columns: TTableColumnDef[];
  assetId?: string;
  make?: string;
  model?: string;
};

export const BladeDamagePlotContainer: React.FunctionComponent<BladeDamagePlotContainerProps> = ({
  isOpen,
  onClose,
  columns,
  assetId,
  make,
  model,
}) => {
  /**
   * Set of refs for unsized divs in the scroll container (see returned jsx) used to determine whether
   * each edge of the container is in view. If an edge is not visible (i.e. the container is below the
   * minimum width and scrolled elsewhere), this is used to add box-shadow in the styled-component to
   * make it clear to the user that there is more to see.
   */
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const isScrolledToTop: boolean = useIsInView(topRef);
  const isScrolledToBottom: boolean = useIsInView(bottomRef);
  const isScrolledToLeft: boolean = useIsInView(leftRef);
  const isScrolledToRight: boolean = useIsInView(rightRef);

  const [selectedBladeSides, setSelectedBladeSides] = useState<BladeSide[]>(BLADE_SIDES);
  const [allowedSelectionBladeSides, setAllowedSelectionBladeSides] = useState<BladeSide[]>([]);
  const [plotStyleState, dispatchPlotStyleState] = useReducer<
    (prevState: PlotStyleState, incomingState: Partial<PlotStyleState>) => PlotStyleState
  >(plotStyleStateReducer, {
    modalOpen: false,
    colorCodingEnabled: false,
    colorCodingAttribute: ColorCodingAttribute.Severity,
  });
  const { colorCodingEnabled, colorCodingAttribute } = plotStyleState;

  // Pull filters from damage table context for querying the same damages here
  const { filteredInfo, updateFilteredInfo } = useContext(DataTableContext);
  const filterBy = useMemo(
    () =>
      getFilterBy({
        filters: { ...filteredInfo, ...(assetId ? { assetId: [assetId] } : {}) },
        columns,
      }).filter(
        (
          f
        ): f is {
          key: any;
          values: any;
          operator: any;
        } => !!f
      ),
    [filteredInfo, columns, assetId]
  );

  /**
   * Get blade shape data by make/model, if provided. Otherwise, get blade shape
   * data as a child of the asset with id props.assetId. This will fall back on
   * empty string make/model, which will return the generic shape.
   *
   * Only one of these two queries will run
   */
  const { data: bladeShapeData } = useGetBladeShapeQuery({
    variables: { input: { make: make ?? '', model: model ?? '' } },
    skip: !!assetId && !make && !model,
  });
  const { data: assetBladeShapeData } = useGetBladeWithShapeQuery({
    variables: { id: assetId },
    skip: !assetId || (!!make && !!model),
  });

  // Fetch first PAGE_SIZE damages; see useEffect below for automatic pagination
  const { data, fetchMore, previousData } = useGetHorizonDamagesForPlotQuery({
    variables: {
      input: {},
      filterBy,
      limit: PAGE_SIZE,
      offset: 0,
    },
    skip: !isOpen,
  });

  /**
   * Automatically fetch damages in pages of PAGE_SIZE. Then render data as it is loaded,
   * reducing the time until the graph becomes usable.
   */
  useEffect(() => {
    if (
      data?.getHorizonDamages?.items &&
      data.getHorizonDamages.items.length !== previousData?.getHorizonDamages?.items?.length
    ) {
      fetchMore({
        variables: {
          offset: data.getHorizonDamages.items.length,
        },
        updateQuery: (previousData, { fetchMoreResult }) => ({
          getHorizonDamages: {
            items: [
              ...(previousData.getHorizonDamages?.items ?? []),
              ...(fetchMoreResult.getHorizonDamages?.items ?? []),
            ],
            totalCount: previousData.getHorizonDamages?.totalCount,
          },
        }),
      });
    }
  }, [data, fetchMore, previousData]);

  const loadingPercent: number = useMemo(
    () =>
      100 *
      (data?.getHorizonDamages?.totalCount === 0
        ? 1
        : !!data?.getHorizonDamages?.totalCount
          ? (data?.getHorizonDamages?.items?.length ?? 0) / data.getHorizonDamages.totalCount
          : 0),
    [data?.getHorizonDamages]
  );

  const damagesByBladeSide: { [Property in BladeSide]: AtlasGqlHorizonDamage[] } = useMemo(
    () =>
      groupBy(data?.getHorizonDamages?.items, 'primaryObservationGroupAttrs["Blade Side"]') as {
        [Property in BladeSide]: AtlasGqlHorizonDamage[];
      },
    [data?.getHorizonDamages?.items]
  );

  const colorCodingMap: ColorCodingMap | undefined = useMemo(() => {
    if (
      colorCodingEnabled &&
      !!colorCodingAttribute &&
      [ColorCodingAttribute.OpSeverity, ColorCodingAttribute.Type].includes(colorCodingAttribute)
    ) {
      const colorCodingAttributeValues = data?.getHorizonDamages?.items?.reduce<
        (string | number)[]
      >((acc, damage) => {
        const attribute: string | number | null | undefined =
          damage?.primaryObservationGroupAttrs[colorCodingAttribute];

        if (!isNil(attribute) && !acc.includes(attribute)) {
          return [...acc, attribute];
        }
        return acc;
      }, []);

      return colorCodingAttributeValues ? getColorCodingMap(colorCodingAttributeValues) : undefined;
    }
    if (colorCodingEnabled && colorCodingAttribute === ColorCodingAttribute.Severity) {
      return severityColorCodingMap;
    }
    return undefined;
  }, [data?.getHorizonDamages?.items, colorCodingEnabled, colorCodingAttribute]);

  const { bladeShapeWidths, bladeLength }: { bladeShapeWidths: number[]; bladeLength: number } =
    useMemo(
      () => ({
        bladeShapeWidths:
          bladeShapeData?.genericShapeData?.crossSectionWidths ??
          assetBladeShapeData?.genericShapeData?.crossSectionWidths ??
          [],
        bladeLength:
          bladeShapeData?.makeModelData?.length ??
          ((assetBladeShapeData?.assetWithMetadata as AtlasGqlBlade)?.bladeMakeModel
            ?.length as number),
      }),
      [bladeShapeData, assetBladeShapeData]
    );

  // When an area is selected on a plot, sets the blade side, distance, and chord filters on the table and plot accordingly
  const handleAreaSelection = useCallback(
    ({
      bladeSide,
      distanceRange,
      chordRange,
    }: {
      bladeSide: BladeSide | null;
      distanceRange: [number, number] | null;
      chordRange?: [number, number] | null;
    }) => {
      setAllowedSelectionBladeSides(bladeSide ? [bladeSide] : []);
      updateFilteredInfo({
        ...filteredInfo,
        'Blade Side': bladeSide ? [bladeSide] : null,
        Distance: distanceRange ? [distanceRange] : null,
        Chord: chordRange ? [chordRange] : null,
      });
    },
    [filteredInfo, updateFilteredInfo]
  );

  const areaSelected = allowedSelectionBladeSides.length === 1;

  return (
    <>
      <PlotContainer
        className={isOpen ? 'open' : ''}
        $isScrolledToBottom={isScrolledToBottom}
        $isScrolledToLeft={isScrolledToLeft}
        $isScrolledToRight={isScrolledToRight}
      >
        <PlotHeader $isScrolledToTop={isScrolledToTop}>
          <div>
            <span>Blade Side:</span>
            <Select
              options={BLADE_SIDES.map(side => ({
                value: side,
                disabled: isEqual(selectedBladeSides, [side]),
              }))}
              value={selectedBladeSides}
              onChange={sides =>
                setSelectedBladeSides(BLADE_SIDES.filter(side => sides.includes(side)))
              }
              showSearch={true}
              mode="multiple"
            />
            {loadingPercent !== 100 && (
              <Tooltip
                title={
                  data?.getHorizonDamages?.totalCount
                    ? `${data?.getHorizonDamages?.items?.length ?? 0}/${data.getHorizonDamages.totalCount} damages loaded`
                    : ''
                }
              >
                <Progress
                  style={{
                    cursor: 'pointer',
                    marginLeft: '1rem',
                    width: '200px',
                  }}
                  strokeColor={COLORS.TEAL}
                  percent={loadingPercent}
                  showInfo={false}
                />
              </Tooltip>
            )}
          </div>

          <div>
            <Button
              icon={areaSelected ? <UndoAreaSelectionIcon /> : <AreaSelectionIcon />}
              onClick={() => {
                if (areaSelected) {
                  handleAreaSelection({
                    bladeSide: null,
                    distanceRange: null,
                    chordRange: null,
                  });
                } else {
                  setAllowedSelectionBladeSides(BLADE_SIDES);
                }
              }}
              title="Select an area to filter by"
            />
            <Button
              icon={<FormatPainterFilled />}
              onClick={() => dispatchPlotStyleState({ modalOpen: true })}
              title="Color code damages by attribute"
            />
            <Button icon={<DownOutlined />} onClick={onClose} title="Close Plot" />
          </div>
        </PlotHeader>
        <div ref={topRef} />
        <PlotGrid $isScrolledToLeft={isScrolledToLeft} $isScrolledToRight={isScrolledToRight}>
          <div ref={leftRef} />
          <div>
            {BLADE_SIDES.map(bladeSide => (
              <BladeDamagePlot
                key={bladeSide}
                visible={selectedBladeSides.includes(bladeSide)}
                bladeSide={bladeSide}
                damages={damagesByBladeSide[bladeSide] as AtlasGqlHorizonDamage[]}
                loading={loadingPercent !== 100}
                shapeWidths={bladeShapeWidths}
                bladeLength={bladeLength}
                colorCodingAttribute={colorCodingEnabled ? colorCodingAttribute : undefined}
                colorCodingMap={colorCodingMap}
                allowAreaSelection={allowedSelectionBladeSides.includes(bladeSide)}
                onAreaSelection={handleAreaSelection}
              />
            ))}
          </div>
          <div ref={rightRef} />
          {colorCodingEnabled && colorCodingMap && (
            <ColorCodingLegend
              colorCodingAttribute={colorCodingAttribute}
              colorCodingMap={colorCodingMap}
            />
          )}
        </PlotGrid>
        <div ref={bottomRef} />
      </PlotContainer>
      <PlotStyleOptionsModal
        plotStyleState={plotStyleState}
        dispatchPlotStyleState={dispatchPlotStyleState}
      />
    </>
  );
};
