// Note: constants in this file must have a corresponding action setup in the
// `auth` service first. Otherwise the flags below won't do anything. 🫠
//
// When adding a new action, in auth service you'll need to:
//    1. add a new action constant
//    2. add the new action constant to the `ACTIONS` array, which populates the
//      graphql enum `Action`
//    3. add the new action constant to the `ACTION_MAP` object associated with
//      1 or more roles
//    4. regenerate the graphql types to pick up the new action in the enum
//    5. add an entry in the `GqlActionOverride` in the type overrides

export const FLEET_TAB_VISIT = 'FLEET_TAB_VISIT';
export const INSPECTIONS_TAB_VISIT = 'INSPECTIONS_TAB_VISIT';
export const WORK_ORDERS_TAB_VISIT = 'WORK_ORDERS_TAB_VISIT';
export const ANALYTICS_TAB_VISIT = 'ANALYTICS_TAB_VISIT';
export const TASKS_TAB_VISIT = 'TASKS_TAB_VISIT';
export const T_LINE_TAB_VISIT = 'T_LINE_TAB_VISIT';

export const TURBINE_EDIT = 'TURBINE_EDIT';
export const TURBINE_EDIT_MAKE = 'TURBINE_EDIT_MAKE';
export const TURBINE_EDIT_MODEL = 'TURBINE_EDIT_MODEL';
export const TURBINE_EDIT_STATUS = 'TURBINE_EDIT_STATUS';
export const TURBINE_EDIT_AUX_COMPONENT = 'TURBINE_EDIT_AUX_COMPONENT';

export const DAMAGE_CREATE = 'DAMAGE_CREATE';
export const DAMAGE_EDIT_ATTRIBUTES = 'DAMAGE_EDIT_ATTRIBUTES';
export const DAMAGE_EDIT_SHAPE = 'DAMAGE_EDIT_SHAPE';
export const DAMAGE_DELETE = 'DAMAGE_DELETE';
export const DAMAGE_CONFIRM = 'DAMAGE_CONFIRM';

export const WORK_CAMPAIGN_CREATE = 'WORK_CAMPAIGN_CREATE';
export const WORK_CAMPAIGN_DELETE = 'WORK_CAMPAIGN_DELETE';
export const WORK_CAMPAIGN_EDIT = 'WORK_CAMPAIGN_EDIT';
export const WORK_CAMPAIGN_EDIT_DESCRIPTION = 'WORK_CAMPAIGN_EDIT_DESCRIPTION';
export const WORK_CAMPAIGN_EDIT_DUE_DATE = 'WORK_CAMPAIGN_EDIT_DUE_DATE';
export const WORK_CAMPAIGN_EDIT_NAME = 'WORK_CAMPAIGN_EDIT_NAME';
export const WORK_CAMPAIGN_EDIT_STATUS = 'WORK_CAMPAIGN_EDIT_STATUS';
export const WORK_CAMPAIGN_EDIT_WORK_ORDER_PRIORITY = 'WORK_CAMPAIGN_EDIT_WORK_ORDER_PRIORITY';

export const WORK_ORDER_CREATE = 'WORK_ORDER_CREATE';
export const WORK_ORDER_DELETE = 'WORK_ORDER_DELETE';
export const WORK_ORDER_EDIT = 'WORK_ORDER_EDIT';
export const WORK_ORDER_EDIT_BULK = 'WORK_ORDER_EDIT_BULK';
export const WORK_ORDER_EDIT_COLLABORATORS = 'WORK_ORDER_EDIT_COLLABORATORS';
export const WORK_ORDER_EDIT_DESCRIPTION = 'WORK_ORDER_EDIT_DESCRIPTION';
export const WORK_ORDER_EDIT_DUE_DATE = 'WORK_ORDER_EDIT_DUE_DATE';
export const WORK_ORDER_EDIT_HOURS_ACT = 'WORK_ORDER_EDIT_HOURS_ACT';
export const WORK_ORDER_EDIT_HOURS_EST = 'WORK_ORDER_EDIT_HOURS_EST';
export const WORK_ORDER_EDIT_NAME = 'WORK_ORDER_EDIT_NAME';
export const WORK_ORDER_EDIT_STATUS = 'WORK_ORDER_EDIT_STATUS';
export const WORK_ORDER_EDIT_TASK_PRIORITY = 'WORK_ORDER_EDIT_TASK_PRIORITY';
export const WORK_ORDER_EDIT_CAMPAIGN = 'WORK_ORDER_EDIT_CAMPAIGN';
export const WORK_ORDER_EDIT_VENDOR_ORG = 'WORK_ORDER_EDIT_VENDOR_ORG';
export const WORK_ORDER_UNPLANNED_TASKS_VIEW = 'WORK_ORDER_UNPLANNED_TASKS_VIEW';

export const TASK_CREATE = 'TASK_CREATE';
export const TASK_DELETE = 'TASK_DELETE';
export const TASK_EDIT = 'TASK_EDIT';
export const TASK_EDIT_BULK = 'TASK_EDIT_BULK';
export const TASK_EDIT_DESCRIPTION = 'TASK_EDIT_DESCRIPTION';
export const TASK_EDIT_DUE_DATE = 'TASK_EDIT_DUE_DATE';
export const TASK_EDIT_COMPLETED_DATE = 'TASK_EDIT_COMPLETED_DATE';
export const TASK_EDIT_STATUS = 'TASK_EDIT_STATUS';
export const TASK_EDIT_TAGS = 'TASK_EDIT_TAGS';
export const TASK_EDIT_VENDOR = 'TASK_EDIT_VENDOR';
export const TASK_EDIT_WORK_ORDER = 'TASK_EDIT_WORK_ORDER';

export const TASK_STEP_CREATE = 'TASK_STEP_CREATE';
export const TASK_STEP_EDIT_PRIORITY = 'TASK_STEP_EDIT_PRIORITY';
export const TASK_COMMENT_CREATE = 'TASK_COMMENT_CREATE';

export const TASK_TEMPLATES = 'TASK_TEMPLATES';

export const TAG_CREATE = 'TAG_CREATE';
export const TAG_DELETE = 'TAG_DELETE';

export const COMMENT_CREATE = 'COMMENT_CREATE';
export const WORK_LOG_CREATE = 'WORK_LOG_CREATE';

export const FLEET_ATTACHMENT_VIEW = 'FLEET_ATTACHMENT_VIEW';
export const WORK_CAMPAIGN_ATTACHMENT_VIEW = 'WORK_CAMPAIGN_ATTACHMENT_VIEW';
export const WORK_ORDER_ATTACHMENT_VIEW = 'WORK_ORDER_ATTACHMENT_VIEW';
export const TASK_ATTACHMENT_VIEW = 'TASK_ATTACHMENT_VIEW';
export const ATTACHMENT_CREATE = 'ATTACHMENT_CREATE';

export const INSPECTIONS_UPLOAD_CREATE = 'INSPECTIONS_UPLOAD_CREATE';
export const INSPECTIONS_UPLOAD_TAB_VISIT = 'INSPECTIONS_UPLOAD_TAB_VISIT';
export const INSPECTIONS_THIRD_PARTY_PROCESS = 'INSPECTIONS_THIRD_PARTY_PROCESS';
export const INSPECTIONS_DELETE = 'INSPECTIONS_DELETE';
export const INSPECTIONS_REVIEW = 'INSPECTIONS_DELETE'; // trying to find out where this comes from

export const ALERT_CREATE = 'ALERT_CREATE';
export const ALERT_UPDATE = 'ALERT_UPDATE';

export const ASSET_CREATE = 'ASSET_CREATE';
export const ASSET_DELETE = 'ASSET_DELETE';
export const ASSET_UPDATE = 'ASSET_UPDATE';
