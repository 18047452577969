import { flatten, reverse } from 'lodash';
import { useMemo } from 'react';
import COLORS from 'utils/color/definitions';
import { BladeSide } from './BladeDamagePlotContainer';

export type SelectionRangeProps = {
  distance: [number, number];
  chord?: [number, number];
  rawSelection: [[number, number], [number, number]];
  anchorX: number;
  anchorY: number;
  textAnchor: 'start' | 'end';
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  shapePoints: [number, number][];
  bladeLength: number;
  bladeSide: BladeSide;
};

/**
 * Given the selection drawn by a D3 Brush, redraws the box to match the blade
 * shape and contour, and adds a label of the selected distance and chord ranges
 */

export const SelectionRange: React.FunctionComponent<SelectionRangeProps> = ({
  distance,
  chord,
  anchorX,
  anchorY,
  textAnchor,
  xScale,
  yScale,
  rawSelection,
  shapePoints,
  bladeLength,
  bladeSide,
}) => {
  const yRange = useMemo(() => yScale.range(), [yScale]);

  // Subset of points for the blade shape curve at the selected distance range
  const shapePointsSubset = shapePoints.slice(
    Math.round((100 * distance[0]) / bladeLength),
    Math.round((100 * distance[1]) / bladeLength) + 1
  );

  return (
    <>
      {bladeSide === BladeSide.PressureSide && chord && (
        <path
          d={[
            ...flatten(
              shapePointsSubset.map((point, i) => [
                i === 0 ? 'M' : 'L',
                xScale(point[0]),
                yScale(point[1]) + ((100 - chord[0]) / 100) * (yRange[0] - yScale(point[1])),
              ])
            ),
            ...flatten(
              reverse(shapePointsSubset).map(point => [
                'L',
                xScale(point[0]),
                yScale(point[1]) + ((100 - chord[1]) / 100) * (yRange[0] - yScale(point[1])),
              ])
            ),
            'Z',
          ].join(' ')}
        />
      )}
      {bladeSide === BladeSide.SuctionSide && chord && (
        <path
          d={[
            ...flatten(
              shapePointsSubset.map((point, i) => [
                i === 0 ? 'M' : 'L',
                xScale(point[0]),
                (chord[1] / 100) * yScale(point[1]),
              ])
            ),
            ...flatten(
              reverse(shapePointsSubset).map(point => [
                'L',
                xScale(point[0]),
                (chord[0] / 100) * yScale(point[1]),
              ])
            ),
            'Z',
          ].join(' ')}
        />
      )}
      {[BladeSide.LeadingEdge, BladeSide.TrailingEdge].includes(bladeSide) && (
        <path
          d={[
            'M',
            rawSelection[0][0],
            35,
            'L',
            rawSelection[1][0],
            35,
            'L',
            rawSelection[1][0],
            47,
            'L',
            rawSelection[0][0],
            47,
            'Z',
          ].join(' ')}
        />
      )}
      <text transform={`translate(${anchorX}, ${anchorY})`} style={{ textAnchor }} fill="#0000008C">
        <tspan x="0" dy="1em">
          dist: {distance[0].toFixed(1)}m to {distance[1].toFixed(1)}m
        </tspan>

        {chord && (
          <tspan x="0" dy="1em">
            chord: {chord[0].toFixed(0)}% to {chord[1].toFixed(0)}%
          </tspan>
        )}
      </text>
    </>
  );
};
