// Jo Jeavons 1/1/2024 - These are custom fields that we know we either do not want to display, or are already represented on the Task.
// This list should rarely change, and these specific fields are the exception to the expectation of how standard fields will work going forward,
// so we're fine keeping it as a hard-coded array of strings.
export const standardFieldSlugsToIgnore = [
  'collaborators',
  'completed_date',
  'created_by',
  'created',
  'due_date',
  'name',
  'parent',
  'status',
  'tags',
  'task_type',
  'vendor_organization',
  'vendor',
];
