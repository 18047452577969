import styled from 'styled-components';
import { Select } from 'antd5';

export const VendorSelect = styled(Select)`
  min-width: 10rem;
`;

export const AddVendorContainer = styled.div`
  cursor: pointer;
  padding: 0.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor};
  }
`;
